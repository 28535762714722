import React, { useState, useEffect, useRef } from 'react';
import './InjuryReportTable.css';
import { usePlayerColors } from './PlayerColorContext';
import { combinedInfo } from '../components/CombinedInfo';
import { fetchData } from "../api";
import { useKeycloak } from '../KeycloakProvider';
import teamNameToFullName from '../components/TeamNameToFullName';

const InjuryReportTable = ({ selectedFeature }) => {
  const { selectedPlayer, teamColors } = usePlayerColors();
  const { token } = useKeycloak();

  const [isEditing, setIsEditing] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [homeTeamData, setHomeTeamData] = useState(null);
  const [opponentTeamData, setOpponentTeamData] = useState(null);
  const [homeImpactData, setHomeImpactData] = useState({});
  const [opponentImpactData, setOpponentImpactData] = useState({});
  const [teamOptions, setTeamOptions] = useState([]);
  const [selectedTeamLogo, setSelectedTeamLogo] = useState('/default-logo.png');
  const [dataFetched, setDataFetched] = useState(false);
  const wrapperRef = useRef(null);

  // Utility function for consistent team name mapping
  const getMappedTeamName = (teamName) => {
    return teamName === "Los Angeles Clippers" ? "LA Clippers" : teamName;
  };

  // Mapping for features
  const featureMap = {
    "Total": "pts",
    "AST": "ast",
    "REB": "reb",
    "BLK": "blk",
    "STL": "stl",
    "Pts+Ast": "Pts+Ast",
    "Pts+Reb": "Pts+Reb",
    "Ast+Reb": "Ast+Reb",
    "Pts+Ast+Reb": "Pts+Ast+Reb"
  };

  const visualFeatureMap = {
    "Total": "Points",
    "AST": "Assists",
    "REB": "Rebounds",
    "BLK": "Blocks",
    "STL": "Steals",
    "Pts+Ast": "Pts+Ast",
    "Pts+Reb": "Pts+Reb",
    "Ast+Reb": "Ast+Reb",
    "Pts+Ast+Reb": "Pts+Ast+Reb"
  };

  useEffect(() => {
    if (selectedPlayer) {
      const player = combinedInfo[selectedPlayer];
      if (player) {
        const teamName = player.teamName;
        const opponentName = player.opponentName;
        setTeamOptions([teamName, opponentName]);
        setSelectedTeam(teamName);
        setSelectedTeamLogo(player.teamLogoSrc);
      }
    }
  }, [selectedPlayer]);

  const displayTeamName = getMappedTeamName(teamNameToFullName[selectedTeam]);

  useEffect(() => {
    if (selectedTeam && token) {
      const playerInfo = combinedInfo[selectedPlayer];
      const isOpponentSelected = selectedTeam === playerInfo.opponentName;

      const fetchInjuryReport = async () => {
        try {
          const endpoint = `teams/injury_report_view/?team_name=${displayTeamName}`;
          const data = await fetchData(endpoint, token);

          if (isOpponentSelected) {
            setOpponentTeamData(data.filter(player => player.team_name === displayTeamName));
            setHomeTeamData([]); // Reset home team data
          } else {
            setHomeTeamData(data.filter(player => player.team_name === displayTeamName));
            setOpponentTeamData([]); // Reset opponent team data
          }

          const teamInfo = Object.values(combinedInfo).find(info => info.teamName === selectedTeam);
          if (teamInfo) {
            setSelectedTeamLogo(teamInfo.teamLogoSrc);
          }
        } catch (error) {
          console.error('Error fetching injury report:', error);
        }
      };

      fetchInjuryReport();
    }
  }, [selectedTeam, token, selectedPlayer]);

  useEffect(() => {
    const fetchImpactData = async () => {
      setDataFetched(false);
      if (selectedFeature && token && selectedPlayer) {
        const playerInfo = combinedInfo[selectedPlayer];
        const isOpponentSelected = selectedTeam === playerInfo.opponentName;

        const homeImpactResults = {};
        const opponentImpactResults = {};

        if (!isOpponentSelected && homeTeamData && homeTeamData.length > 0) {
          for (const item of homeTeamData) {
            try {
              const endpoint = `player-impact/get_player_impact/`;
              const params = new URLSearchParams({
                main_player_id: selectedPlayer,
                impact_player: item.player_id,
                team_name: getMappedTeamName(teamNameToFullName[playerInfo.teamName]),
                feature: featureMap[selectedFeature]
              });
              const data = await fetchData(`${endpoint}?${params.toString()}`, token);

              homeImpactResults[item.player_id] = data.impact_value !== undefined
                ? Math.round(data.impact_value * 10) / 10
                : null;
            } catch (error) {
              console.error(`Error fetching impact for player ${item.player_id}:`, error);
            }
          }
          setHomeImpactData(homeImpactResults);
        }

        if (isOpponentSelected && opponentTeamData && opponentTeamData.length > 0) {
          for (const item of opponentTeamData) {
            try {
              const endpoint = `team-impact/get_team_impact/`;
              const params = new URLSearchParams({
                player_name: item.player_id,
                team_name: getMappedTeamName(teamNameToFullName[selectedTeam]),
                feature: featureMap[selectedFeature]
              });
              const data = await fetchData(`${endpoint}?${params.toString()}`, token);

              opponentImpactResults[item.player_id] = data.impact_allowed_value !== undefined
                ? Math.round(Math.abs(data.impact_allowed_value) * 10) / 10
                : null;
            } catch (error) {
              console.error(`Error fetching impact for player ${item.player_id}:`, error);
            }
          }
          setOpponentImpactData(opponentImpactResults);
        }
        setDataFetched(true);
      }
    };

    if (homeTeamData !== null || opponentTeamData !== null) {
      fetchImpactData();
    }
  }, [homeTeamData, opponentTeamData, selectedFeature, selectedPlayer, selectedTeam, token]);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsEditing(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleTeamChange = (team) => {
    setSelectedTeam(team);
    setIsEditing(false);
  };

  const teamColor = teamColors[0] || '#000';
  const highlightColor = teamColors[1] || '#fff';

  const renderImpactText = (impactValue, home_or_away) => {
    if (!dataFetched) return 'Loading...';
    if (impactValue === null || impactValue === 0 || isNaN(impactValue)) return 'No impact';
    const verb = home_or_away === 'home' ? 'Gets' : 'Team Allows';
    return impactValue > 0
      ? `${verb} ${impactValue} more ${visualFeatureMap[selectedFeature]}`
      : `${verb} ${Math.abs(impactValue)} less ${visualFeatureMap[selectedFeature]}`;
  };

  const comparePlayers = (a, b) => {
    const aHasImpact = a.impactValue !== null && a.impactValue !== 0 && !isNaN(a.impactValue);
    const bHasImpact = b.impactValue !== null && b.impactValue !== 0 && !isNaN(b.impactValue);
    if (aHasImpact && !bHasImpact) return -1;
    if (!aHasImpact && bHasImpact) return 1;
    if (aHasImpact && bHasImpact) return b.impactValue - a.impactValue;
    return compareStatus(a, b);
  };

  const compareStatus = (a, b) => {
    const statusPriority = { 'Out': 1, 'Day-To-Day': 2, 'N/A': 3 };
    const aStatusPriority = statusPriority[a.status] || 4;
    const bStatusPriority = statusPriority[b.status] || 4;
    return aStatusPriority - bStatusPriority;
  };

  return (
    <div className="injury-report-container" style={{ backgroundColor: 'white' }}>
      <div className="table-header" ref={wrapperRef} style={{ color: 'black' }}>
        <img src={selectedTeamLogo} alt="Team Logo" className="injury-logo" />
        <div className="title">Injury Report</div>
        <div className="selected-team-container" onClick={() => setIsEditing(!isEditing)}>
          <div className="selected-team">{selectedTeam || "Select team..."}</div>
          <div className="dropdown-arrow-injury-report">▼</div>
        </div>
        {isEditing && (
          <div className="autocomplete-injury-table-container" style={{ backgroundColor: teamColor }}>
            {teamOptions.map((team, index) => (
              <div
                key={index}
                className="autocomplete-injury-table-item"
                onClick={() => handleTeamChange(team)}
                style={{ backgroundColor: teamColor, color: 'white' }}
                onMouseOver={(e) => e.currentTarget.style.backgroundColor = highlightColor}
                onMouseOut={(e) => e.currentTarget.style.backgroundColor = teamColor}
              >
                {team}
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Home Team Impact Table */}
      {selectedTeam === combinedInfo[selectedPlayer]?.teamName && (
        <div className="table-container">
          <table className="injury-report-table" style={{ color: 'black' }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Status</th>
                <th>Impact</th>
              </tr>
            </thead>
            <tbody>
              {homeTeamData === null ? (
                <tr>
                  <td colSpan="3">Loading...</td>
                </tr>
              ) : homeTeamData.length > 0 ? (
                (() => {
                  // Combine homeTeamData with impactValue
                  const combinedHomeData = homeTeamData.map(item => {
                    const impactValue = homeImpactData[item.player_id];
                    return {
                      ...item,
                      impactValue: impactValue,
                    };
                  });

                  // Sort combinedHomeData
                  combinedHomeData.sort(comparePlayers);

                  return combinedHomeData.map((item, index) => {
                    const impactValue = item.impactValue;
                    const impactText = renderImpactText(impactValue, 'home');
                    return (
                      <tr key={index}>
                        <td>{item.player_id || 'N/A'}</td>
                        <td>
                          <span className={`status-indicator ${item.status === 'Day-To-Day' ? 'status-day-to-day' : item.status === 'Out' ? 'status-out' : ''}`}></span>
                          {item.status || 'N/A'}
                        </td>
                        <td>{impactText}</td>
                      </tr>
                    );
                  });
                })()
              ) : (
                <tr>
                  <td colSpan="3">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      {/* Opponent Team Impact Table */}
      {selectedTeam === combinedInfo[selectedPlayer]?.opponentName && (
        <div className="table-container">
          <table className="injury-report-table" style={{ color: 'black' }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Status</th>
                <th>Impact</th>
              </tr>
            </thead>
            <tbody>
              {opponentTeamData === null ? (
                <tr>
                  <td colSpan="3">Loading...</td>
                </tr>
              ) : opponentTeamData.length > 0 ? (
                (() => {
                  // Combine opponentTeamData with impactValue
                  const combinedOpponentData = opponentTeamData.map(item => {
                    const impactValue = opponentImpactData[item.player_id];
                    return {
                      ...item,
                      impactValue: impactValue,
                    };
                  });

                  // Sort combinedOpponentData
                  combinedOpponentData.sort(comparePlayers);

                  return combinedOpponentData.map((item, index) => {
                    const impactValue = item.impactValue;
                    const impactText = renderImpactText(impactValue, 'away');
                    return (
                      <tr key={index}>
                        <td>{item.player_id || 'N/A'}</td>
                        <td>
                          <span className={`status-indicator ${item.status === 'Day-To-Day' ? 'status-day-to-day' : item.status === 'Out' ? 'status-out' : ''}`}></span>
                          {item.status || 'N/A'}
                        </td>
                        <td>{impactText}</td>
                      </tr>
                    );
                  });
                })()
              ) : (
                <tr>
                  <td colSpan="3">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default InjuryReportTable;
