// TicketAnalyzer.js
import React, { useState, useEffect } from 'react';
import AvailablePlayers from '../ticket_analyzer_components/AvailablePlayers';
import SelectedPlayers from '../ticket_analyzer_components/SelectedPlayers';
import ParlayBuilder from '../ticket_analyzer_components/ParlayBuilder';
import { useKeycloak } from '../KeycloakProvider';
import { v4 as uuidv4 } from 'uuid';
import './TicketAnalyzer.css';
import { matchups } from '../components/MatchupInfo';
import { teamsDict } from '../components/TeamInfo';

const TicketAnalyzer = () => {
  const { token, keycloakId } = useKeycloak();
  const [playerIds, setPlayerIds] = useState(() => {
    const cachedSelectedPlayers = JSON.parse(localStorage.getItem('savedPlayers')) || [];
    return cachedSelectedPlayers.map(player => player.playerId);
  });
  const [parlayPlayers, setParlayPlayers] = useState(() => {
    const cachedParlayPlayers = JSON.parse(localStorage.getItem('parlayPlayers')) || [];
    return cachedParlayPlayers;
  });
  const [loading, setLoading] = useState(false);
  const [parsedMatchups, setParsedMatchups] = useState([]);
  const [selectedMatchup, setSelectedMatchup] = useState(null);

  useEffect(() => {
    const parsed = matchups.map((matchup) => {
      const [awayTeam, homeTeam] = matchup.matchup.split(' @ ');
      return { ...matchup, awayTeam, homeTeam };
    });
    setParsedMatchups(parsed);
  }, []);

  const loadSelectedPlayers = () => {
    const cachedPlayers = localStorage.getItem('savedPlayers');
    if (cachedPlayers) {
      const parsedPlayers = JSON.parse(cachedPlayers);
      setPlayerIds(parsedPlayers.map((player) => player.playerId));
      setSelectedMatchup(null); // Clear selected matchup
    }
  };

  const handleMatchupSelect = (matchup) => {
    setLoading(true);
    try {
      const homeTeamFullName = matchup.homeTeam;
      const awayTeamFullName = matchup.awayTeam;

      // Pass the full team names to SelectedPlayers
      setSelectedMatchup({
        homeTeam: homeTeamFullName,
        awayTeam: awayTeamFullName,
      });
      setPlayerIds([]); // Clear playerIds since we'll use matchup data in SelectedPlayers
    } catch (error) {
      console.error('Failed to handle matchup click:', error);
    } finally {
      setLoading(false);
    }
  };

  const addToParlayBuilder = (player) => {
    const playerExists = parlayPlayers.some(p => p.playerId === player.playerId);
    if (!playerExists) {
      const updatedParlayPlayers = [...parlayPlayers, player];
      setParlayPlayers(updatedParlayPlayers);
      localStorage.setItem('parlayPlayers', JSON.stringify(updatedParlayPlayers));
    } else {
      // Optionally handle the case where the player is already in the parlay
    }
  };

  const removeFromSelectedPlayers = (playerId) => {
    const updatedPlayerIds = playerIds.filter(id => id !== playerId);
    setPlayerIds(updatedPlayerIds);

    const updatedPlayers = JSON.parse(localStorage.getItem('savedPlayers')).filter(p => p.playerId !== playerId);
    localStorage.setItem('savedPlayers', JSON.stringify(updatedPlayers));
  };

  const clearParlay = () => {
    setParlayPlayers([]);
    localStorage.removeItem('parlayPlayers');
  };

  const [ticketId] = useState(() => uuidv4()); // Generate ticketId once on mount

  return (
    <div className="ticket-analyzer-wrapper">
      <div className="ticket-analyzer-layout">
        <AvailablePlayers
          loadSelectedPlayers={loadSelectedPlayers}
          parsedMatchups={parsedMatchups}
          onMatchupSelect={handleMatchupSelect}
          loading={loading}
        />

        <div className="main-content">
          <SelectedPlayers 
            playerIds={playerIds} 
            selectedMatchup={selectedMatchup}
            addToParlayBuilder={addToParlayBuilder}
            removeFromSelectedPlayers={removeFromSelectedPlayers}
          />
          <ParlayBuilder 
            parlayPlayers={parlayPlayers} 
            removeFromParlay={(name) => {
              const updatedParlayPlayers = parlayPlayers.filter(p => p.name !== name);
              setParlayPlayers(updatedParlayPlayers);
              localStorage.setItem('parlayPlayers', JSON.stringify(updatedParlayPlayers));
            }}
            clearParlay={clearParlay}
            keycloakId={keycloakId}
            ticketId={ticketId}
            token={token}
          />
        </div>
      </div>
    </div>
  );
};

export default TicketAnalyzer;
