// PlayerDashboard.js
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { usePlayerColors } from '../main_dashboard_components/PlayerColorContext';
import PlayerCard from '../main_dashboard_components/PlayerID';
import TeamVersus from '../main_dashboard_components/TeamVersus';
import InjuryReportTable from '../main_dashboard_components/InjuryReportTable';
import Last15Chart from '../main_dashboard_components/Last15Chart';
import Last15Table from '../main_dashboard_components/L15Table';
import ShootingChart from '../main_dashboard_components/RollingFeature';
import VersusTeamChart from '../main_dashboard_components/VersusTeamChart';
import { players } from '../components/PlayerInfo';
import './PlayerDashboard.css';
import PositionDefense from '../main_dashboard_components/PositionDefense';
import OpponentDefense from '../team_dashboard_components/OpponentDefense';
import PlayerLogsHistorical from '../main_dashboard_components/PlayerLogsHistorical';
import useAuth from '../hooks/useAuth';
import MinuteChart from '../main_dashboard_components/MinuteChart';
import SimilarPlayerPerformance from '../main_dashboard_components/SimilarPlayerPerformance';
import PremiumFeature from '../main_dashboard_components/PremiumFeature';
import PlayerSelection from '../main_dashboard_components/PlayerSelection';
import ShootingAndDefense from '../main_dashboard_components/ShootingAndDefense';
import PlayerPropsTable from '../main_dashboard_components/PlayerPropsTable';
import useFetchPlayerThresholds from '../components/PlayerThresholdData';

const PlayerDashboard = () => {
  const { teamColors, setTeamColors, selectedPlayer, setSelectedPlayer, thresholds } = usePlayerColors();
  const { state } = useLocation();
  const [selectedFeature, setSelectedFeature] = useState('Total');
  const [showSum, setShowSum] = useState(true);
  const [isAuthenticated, , , username, roles] = useAuth();

  // Map the selectedPlayer ID to playerName
  const playerName = players[selectedPlayer]?.name || 'Unknown Player';

  // Fetch thresholds for the selected player
  useFetchPlayerThresholds(playerName);

  useEffect(() => {
    if (state?.playerId) {
      setSelectedPlayer(state.playerId);
      setTeamColors(players[state.playerId].teamColors);
    } else if (!selectedPlayer) {
      const initialPlayer = Object.keys(players)[0];
      setSelectedPlayer(initialPlayer);
      setTeamColors(players[initialPlayer].teamColors);
    }
  }, [state, selectedPlayer, setSelectedPlayer, setTeamColors]);

  const handlePlayerSelect = (playerId) => {
    setSelectedPlayer(playerId);
    setTeamColors(players[playerId].teamColors);
  };

  useEffect(() => {
    // Initialize Application Insights tracking for PlayerDashboard
    (function () {
      const methods = ["trackEvent", "trackException", "trackMetric", "trackPageView", "trackTrace", "setAuthenticatedUserContext", "clearAuthenticatedUserContext"];
      const appInsights = window.appInsights || function (config) {
        function trackFunc(func) {
          return function () {
            const args = arguments;
            appInsights.queue.push(function () { appInsights[func].apply(appInsights, args); });
          };
        }
        const appInsights = { config: config }, d = document, scriptElem = d.createElement("script");
        function setup() { for (let i = 0; i < methods.length; i++) appInsights[methods[i]] = trackFunc(methods[i]); }
        scriptElem.src = config.url || "https://az416426.vo.msecnd.net/scripts/a/ai.0.js";
        d.getElementsByTagName("head")[0].appendChild(scriptElem);
        setup();
        appInsights.queue = [];
        appInsights.version = "1.0";
        return appInsights;
      }({
        instrumentationKey: "YOUR_INSTRUMENTATION_KEY"
      });
      window.appInsights = appInsights;

      // Track a page view event for PlayerDashboard
      appInsights.trackPageView({
        name: "PlayerDashboard",
        properties: { component: "PlayerDashboard" }
      });
    })();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      // Handle resize logic if necessary
    };

    window.addEventListener('resize', handleResize);

    document.querySelectorAll('.player-dashboard-info-button').forEach((button) => {
      button.addEventListener('mouseenter', () => {
        const infoText = button.querySelector('.player-dashboard-info-text');
        const rect = button.getBoundingClientRect();
        infoText.style.top = `${rect.top + window.scrollY - infoText.offsetHeight - 10}px`;
        infoText.style.left = `${rect.left + rect.width / 2 - infoText.offsetWidth / 2}px`;
      });
    });

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleFeatureClick = (feature) => {
    setSelectedFeature(feature);
  };

  const handleToggle = () => {
    setShowSum(!showSum);
  };

  const features = ['Total', 'AST', 'REB', 'Pts+Ast', 'Pts+Reb', 'Ast+Reb', 'Pts+Ast+Reb', 'BLK', 'STL'];

  const featureMap = {
    Points: 'Total',
    Assists: 'AST',
    Rebounds: 'REB',
    Steals: 'STL',
    Blocks: 'BLK',
    'Pts+Ast': 'Pts+Ast',
    'Pts+Reb': 'Pts+Reb',
    'Ast+Reb': 'Ast+Reb',
    'Pts+Ast+Reb': 'Pts+Ast+Reb',
  };

  const displayMap = {
    Total: 'PTS',
    AST: 'AST',
    REB: 'REB',
    'Pts+Ast': 'PTS+AST',
    'Pts+Reb': 'PTS+REB',
    'Ast+Reb': 'AST+REB',
    'Pts+Ast+Reb': 'PTS+AST+REB',
    BLK: 'BLK',
    STL: 'STL',
  };

  const handlePlayerLogsFeatureSelect = (feature) => {
    const mappedFeature = featureMap[feature] || feature;
    setSelectedFeature(mappedFeature);
  };

  const getDisplayFeature = (feature) => {
    return displayMap[feature] || feature.toUpperCase();
  };

  return (
    <div className="player-dashboard-container">
      <div className="player-dashboard-row">
        <div className="player-dashboard-player-card">
          <PlayerCard selectedPlayer={selectedPlayer} handlePlayerSelect={handlePlayerSelect} />
        </div>
        <div className="player-dashboard-injury-report">
          <InjuryReportTable selectedFeature={selectedFeature} />
        </div>
        <div className="player-dashboard-team-versus">
          <TeamVersus showSum={showSum} />
        </div>
      </div>

      <div className="player-dashboard-feature-menu">
        {features.map((feature) => (
          <div
            key={feature}
            className={`player-dashboard-feature-item ${selectedFeature === feature ? 'active' : ''}`}
            onClick={() => handleFeatureClick(feature)}
          >
            {getDisplayFeature(feature)}
            {thresholds[playerName]?.[feature] !== undefined && thresholds[playerName][feature] !== null && (
              <div className="threshold-value">{thresholds[playerName][feature]}</div>
            )}
          </div>
        ))}
      </div>

      <div className="player-dashboard-main-charts">
        <div className="player-dashboard-last-15">
          <Last15Chart
            showSum={showSum}
            onToggle={handleToggle}
            selectedFeature={selectedFeature}
          />
        </div>
        <div className="player-dashboard-last-15-table">
          <Last15Table selectedFeature={selectedFeature} />
        </div>
      </div>

      <div className="player-dashboard-row">
        <div className="player-dashboard-versus-team">
          <VersusTeamChart
            showSum={showSum}
            selectedFeature={selectedFeature}
          />
        </div>
        <div className="player-dashboard-shooting">
          <ShootingChart
            showSum={showSum}
            teamColors={teamColors}
            selectedFeature={selectedFeature}
          />
        </div>
        <div className="player-dashboard-position-defense">
          <PositionDefense selectedFeature={selectedFeature} />
        </div>
        <div className="player-dashboard-premium-feature">
          <PremiumFeature selectedFeature = {selectedFeature}/>
        </div>
        
      </div>

      <div className="player-dashboard-row">
        <div className="player-dashboard-opponent-defense">
          <OpponentDefense teamColors={teamColors} selectedFeature={selectedFeature} />
        </div>
        <div className="player-dashboard-props-table">
          <PlayerPropsTable selectedFeature={selectedFeature} />
        </div>
      </div>

      <div className="player-dashboard-row">
        <div className="player-dashboard-historical-logs">
          <PlayerLogsHistorical
            selectedFeature={selectedFeature}
            setSelectedFeature={handlePlayerLogsFeatureSelect}
          />
        </div>
        <div className="player-dashboard-similar-performance">
          <SimilarPlayerPerformance selectedFeature={selectedFeature} />
        </div>
        <div className="player-dashboard-player-selection">
          <PlayerSelection selectedFeature={selectedFeature} />
        </div>
      </div>
    </div>
  );
};

export default PlayerDashboard;
