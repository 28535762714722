import React, { createContext, useContext, useEffect, useState } from 'react';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';

// Context for Keycloak (auth provider)
const KeycloakContext = createContext(null);

export const KeycloakProvider = ({ children }) => {
  const {
    isAuthenticated,
    isLoading,
    user,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
    error,
  } = useAuth0();

  const [keycloak, setKeycloak] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  const [keycloakId, setKeycloakId] = useState(null);
  const [roles, setRoles] = useState([]); // Add roles to the state
  const [loading, setLoading] = useState(true);

  const namespace = 'https://showstone.io/'; // Custom namespace for Auth0 roles

  useEffect(() => {
    const initializeAuth = async () => {
      if (isLoading) {
        return;
      }

      if (error) {
        console.error('Auth0 Error:', error);
        setAuthenticated(false);
        setLoading(false);
        return;
      }

      if (isAuthenticated) {
        try {
          const accessToken = await getAccessTokenSilently();
          setAuthenticated(true);
          setToken(accessToken);
          setKeycloakId(user?.sub || null);

          // Log the user object for debugging
          

          // Extract roles from user metadata or token
          const userRoles = user?.[`${namespace}roles`] || user?.app_metadata?.roles || [];
         

          setRoles(userRoles);

          setKeycloak({
            login: loginWithRedirect,
            logout: () => logout({ returnTo: window.location.origin }),
          });
        } catch (tokenError) {
          console.error('Error retrieving access token:', tokenError);
          setAuthenticated(false);
        }
      } else {
        setAuthenticated(false);
        setKeycloak({
          login: loginWithRedirect,
          logout: () => logout({ returnTo: window.location.origin }),
        });
      }

      setLoading(false);
    };

    initializeAuth();
  }, [
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
    user,
    error,
  ]);

  return (
    <KeycloakContext.Provider
      value={{
        keycloak, // Object containing login and logout methods
        authenticated, // Whether the user is authenticated
        token, // Access token for API calls
        keycloakId, // Unique user identifier (from Auth0 `sub` claim)
        roles, // User roles
        loading, // Whether Auth0 is still loading
      }}
    >
      {children}
    </KeycloakContext.Provider>
  );
};

export const useKeycloak = () => useContext(KeycloakContext);

// Auth0Provider Wrapper
export const AuthWrapper = ({ children }) => {
  const domain = "auth.showstone.io"; // Custom domain
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE // Replace with your Auth0 API identifier

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience, // Include audience to request an access token
      }}
    >
      <KeycloakProvider>{children}</KeycloakProvider>
    </Auth0Provider>
  );
};
