import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './PremiumFeature.css';
import { usePlayerColors } from './PlayerColorContext';
import { players } from '../components/PlayerInfo';
import { Lock } from 'lucide-react';
import { fetchData } from '../api';
import { useKeycloak } from '../KeycloakProvider'; // Use the custom Keycloak hook

const PremiumFeature = ({ selectedFeature }) => {
  const { teamColors, selectedPlayer } = usePlayerColors();
  const { isAuthenticated, roles, token } = useKeycloak(); // Access roles and token from Keycloak
  const primaryColor = teamColors[0] || '#4f46e5';
  const secondaryColor = teamColors[1] || '#818cf8';
  const playerName = players[selectedPlayer]?.name || 'Top Picks';

  const [data, setData] = useState([]);
  const isPremiumUser = roles.includes('premium'); // Check if the user is premium
 
  const selectedFeatureMap = {
    Total: 'pts',
    AST: 'ast',
    REB: 'reb',
    'Pts+Ast': 'pts_ast',
    'Pts+Reb': 'pts_reb',
    'Ast+Reb': 'ast_reb',
    'Pts+Ast+Reb': 'pts_ast_reb',
  };
  useEffect(() => {
    const fetchPlayerData = async () => {
      if (!token) {
        console.error('User not authenticated or token missing');
        return;
      }

      try {
        // Fetch predictions for the selected player
        const queryParams = new URLSearchParams({
          latest: 'true',
          player_name: players[selectedPlayer]?.name,
        });

        const response = await fetchData(
          `player-feature-predictions/filter_predictions/?${queryParams.toString()}`,
          token // Pass the token from useKeycloak
        );

        // Sort data by feature_probability in descending order
        const sortedData = response.sort(
          (a, b) => b.feature_probability - a.feature_probability
        );

        setData(sortedData);
      } catch (error) {
        console.error('Failed to fetch player predictions:', error);
        setData([]);
      }
    };

    fetchPlayerData();
  }, [selectedPlayer, isAuthenticated, token]);

  // Map features to display-friendly names
  const mapFeatureName = (feature) => {
    const featureMap = {
      pts: 'Points',
      ast: 'Assists',
      reb: 'Rebounds',
      pts_ast: 'Pts+Ast',
      pts_reb: 'Pts+Reb',
      ast_reb: 'Ast+Reb',
      pts_ast_reb: 'Pts+Ast+Reb',
    };
    return featureMap[feature] || feature; // Default to original name if no match
  };

  return (
    <div className="premium-feature-container">
      <div className="premium-feature-header">
        <h2>{playerName} Top Picks</h2>
        {!isPremiumUser && (
          <>
            <span className="premium-feature-subtext">(Buy premium to unlock)</span>
            <Link
              to="/premium"
              className="premium-feature-premium"
              style={{ backgroundColor: secondaryColor }}
            >
              Unlock
            </Link>
          </>
        )}
      </div>
      <table className="premium-feature-table">
        <thead>
          <tr>
            <th>Bet</th>
            <th>Pick</th>
            <th>Odds</th>
            <th>Probability</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((item, index) => {
              // Adjusted probability scaling with skewing
              const adjustedProbability =
                item.feature_probability >= 0.5
                  ? (item.feature_probability - 0.5) * 1.5 + 0.5 // Reduced skew above 50%
                  : (item.feature_probability - 0.5) * 1.2 + 0.5; // Reduced scaling below 50%
              const barWidth = Math.max(0, Math.min(adjustedProbability * 100, 100));
        
              return (
                <tr
                  key={index}
                  className={item.feature === selectedFeatureMap[selectedFeature] ? 'selected-row' : ''}
                >
                  <td>
                    {isPremiumUser ? (
                      `${mapFeatureName(item.feature)} ${item.threshold}`
                    ) : (
                      <>
                        <Lock size={16} color={secondaryColor} /> Locked
                      </>
                    )}
                  </td>
                  <td>
                    {isPremiumUser ? (
                      item.over_under
                    ) : (
                      <>
                        <Lock size={16} color={secondaryColor} /> Locked
                      </>
                    )}
                  </td>
                  <td>
                    {isPremiumUser ? (
                      item.odds || 'N/A'
                    ) : (
                      <>
                        <Lock size={16} color={secondaryColor} /> Locked
                      </>
                    )}
                  </td>
                  <td>
                    <div className="premium-feature-probability-bar">
                      <div
                        className="premium-feature-probability-bar-inner"
                        style={{
                          width: `${barWidth}%`,
                          backgroundColor: primaryColor,
                        }}
                      ></div>
                      <span className="premium-feature-probability-tooltip">
                        {(item.feature_probability * 100).toFixed(1)}%
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="4">No data available.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PremiumFeature;
