import React, { useState, useEffect } from "react";
import "./LiveData.css";

function LiveData() {
  const [liveData, setLiveData] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [team, setTeam] = useState("");
  const [matchup, setMatchup] = useState("");
  const [availablePlayers, setAvailablePlayers] = useState([]);

  // Fetch live data from the backend
  const fetchLiveData = async () => {
    const params = new URLSearchParams();
    if (team) params.append("team", team);
    if (matchup) params.append("matchup", matchup);

    try {
      const response = await fetch(`/api/live-data/?${params.toString()}`);
      const data = await response.json();

     
      // Filter out players with all-zero stats
      const filteredGames = data.games.map((game) => ({
        ...game,
        players: game.players.filter(
          (player) => player.pts || player.ast || player.reb
        ),
      }));

   
      setLiveData(filteredGames);

      // Extract available player names for dropdown
      const players = new Set(
        filteredGames.flatMap((game) => game.players.map((p) => p.name))
      );
      setAvailablePlayers(Array.from(players));
    } catch (error) {
      console.error("Error fetching live data:", error);
    }
  };

  // Periodic refresh for live data
  useEffect(() => {
    fetchLiveData();
    const interval = setInterval(fetchLiveData, 60000); // Refresh every 60 seconds
    return () => clearInterval(interval);
  }, [team, matchup]);

  // Add a player row dynamically
  const addPlayer = (playerName) => {
    if (!selectedPlayers.some((p) => p.name === playerName)) {
      setSelectedPlayers((prev) => [
        ...prev,
        { name: playerName, feature: "pts", threshold: 10, condition: "over" },
      ]);
    }
  };

  // Update player feature, threshold, or condition
  const updatePlayer = (playerName, key, value) => {
    setSelectedPlayers((prev) =>
      prev.map((p) =>
        p.name === playerName ? { ...p, [key]: value } : p
      )
    );
  };

  // Remove a player from the list
  const removePlayer = (playerName) => {
    setSelectedPlayers((prev) => prev.filter((p) => p.name !== playerName));
  };

  // Render Player View rows
  const renderPlayerRows = () =>
    selectedPlayers.map((player) => {
      const playerData = liveData
        .flatMap((game) => game.players)
        .find((p) => p.name === player.name);

      if (!playerData) {
        return (
          <div key={player.name} className="player-row">
            <div className="player-info">{player.name}</div>
            <div className="player-feature">N/A</div>
            <div className="player-threshold">N/A</div>
            <div className="player-condition">N/A</div>
            <div className="player-bar-container">No Data</div>
            <div className="player-remove">
              <button onClick={() => removePlayer(player.name)} className="remove-btn">X</button>
            </div>
          </div>
        );
      }

      const value = playerData[player.feature] || 0;
      const isConditionMet =
        (player.condition === "over" && value > player.threshold) ||
        (player.condition === "under" && value <= player.threshold);


      return (
        <div key={player.name} className="player-row">
          <div className="player-info">{player.name}</div>
          <div className="player-feature">
            <select
              value={player.feature}
              onChange={(e) =>
                updatePlayer(player.name, "feature", e.target.value)
              }
            >
              <option value="pts">Points</option>
              <option value="ast">Assists</option>
              <option value="reb">Rebounds</option>
              <option value="pts+ast">Pts+Ast</option>
              <option value="pts+reb">Pts+Reb</option>
              <option value="pts+ast+reb">Pts+Ast+Reb</option>
            </select>
          </div>
          <div className="player-threshold">
            <input
              type="number"
              value={player.threshold}
              onChange={(e) =>
                updatePlayer(player.name, "threshold", Number(e.target.value))
              }
            />
          </div>
          <div className="player-condition">
            <select
              value={player.condition}
              onChange={(e) =>
                updatePlayer(player.name, "condition", e.target.value)
              }
            >
              <option value="over">Over</option>
              <option value="under">Under</option>
            </select>
          </div>
          <div className="player-bar-container">
            <div
              className="player-bar-fill"
              style={{
                width: `${Math.min((value / player.threshold) * 100, 100)}%`,
                backgroundColor: isConditionMet ? "green" : "red",
              }}
            >
              <span className="player-bar-value">{value}</span>
            </div>
          </div>
          <div className="player-remove">
            <button onClick={() => removePlayer(player.name)} className="remove-btn">X</button>
          </div>
        </div>
      );
    });

  return (
    <div className="live-data-container">
      <h1>Live NBA Data</h1>

      {/* Filters */}
      <div className="filters">
        <input
          type="text"
          placeholder="Team"
          value={team}
          onChange={(e) => setTeam(e.target.value)}
        />
        <input
          type="text"
          placeholder="Matchup"
          value={matchup}
          onChange={(e) => setMatchup(e.target.value)}
        />
        <select
          onChange={(e) => addPlayer(e.target.value)}
          value=""
          className="player-dropdown"
        >
          <option value="" disabled>
            Select Player
          </option>
          {availablePlayers.map((p) => (
            <option key={p} value={p}>
              {p}
            </option>
          ))}
        </select>
      </div>

      {/* Player Table Header */}
      <div className="player-header">
        <div className="player-info">Player</div>
        <div className="player-feature">Feature</div>
        <div className="player-threshold">Threshold</div>
        <div className="player-condition">Condition</div>
        <div className="player-bar-header">Performance</div>
        <div className="player-remove"></div>
      </div>

      {/* Player Rows */}
      <div className="player-view">{renderPlayerRows()}</div>
    </div>
  );
}

export default LiveData;
