import React, { useState, useEffect, useRef } from 'react';
import './MatchupInjuryReportTable.css';
import { teamsDict } from '../../components/TeamInfo';
import { fetchData } from "../../api";
import { useKeycloak } from '../../KeycloakProvider';
import teamAbbreviationToFullName from '../../components/TeamFullNameDictionary';
import { getPlayerId } from '../../components/PlayerLookup';
import { abbreviationToTeamName } from '../../components/TeamDictionary';

const MatchupInjuryReportTable = ({ homeTeam, awayTeam }) => {
  const { token } = useKeycloak();
  const [isEditing, setIsEditing] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(homeTeam);
  const [injuredPlayers, setInjuredPlayers] = useState([]);
  const [offensiveImpacts, setOffensiveImpacts] = useState({});
  const [defensiveImpacts, setDefensiveImpacts] = useState({});
  const [teamOptions, setTeamOptions] = useState([homeTeam, awayTeam]);
  const [selectedTeamLogo, setSelectedTeamLogo] = useState('/default-logo.png');
  const wrapperRef = useRef(null);

  const feature = "pts"; // Use "pts" for both offensive and defensive impact

  useEffect(() => {
    setSelectedTeam(homeTeam);
    setTeamOptions([homeTeam, awayTeam]);
  }, [homeTeam, awayTeam]);

  useEffect(() => {
    if (selectedTeam && token) {
      setInjuredPlayers([]);
      setSelectedTeamLogo('/default-logo.png');

      const fetchInjuryReport = async () => {
        try {
          const teamFullName = teamAbbreviationToFullName[selectedTeam];
          const endpoint = `teams/injury_report_view/?team_name=${teamFullName}`;
          const data = await fetchData(endpoint, token);
          setInjuredPlayers(data || []);
          setSelectedTeamLogo(teamsDict[abbreviationToTeamName[selectedTeam]]?.logo || '/default-logo.png');
        } catch (error) {
          console.error('Error fetching injury report:', error);
        }
      };

      fetchInjuryReport();
    }
  }, [selectedTeam, token]);

  useEffect(() => {
    const fetchImpacts = async () => {
      if (injuredPlayers.length > 0 && token && selectedTeam) {
        const teamFullName = teamAbbreviationToFullName[selectedTeam];
        const offensiveResults = {};
        const defensiveResults = {};

        for (const player of injuredPlayers) {
          try {
            const playerId = getPlayerId(player.player_id);

            // Fetch offensive impact
            const offensiveParams = new URLSearchParams({
              team_name: teamFullName,
              feature,
              player_id: playerId,
            });
            const offensiveData = await fetchData(`team-impact/get_team_impact/?${offensiveParams.toString()}`, token);
            offensiveResults[playerId] = offensiveData?.impact_value ? parseFloat(offensiveData.impact_value.toFixed(1)) : null;

            // Fetch defensive impact
            const defensiveParams = new URLSearchParams({
              team_name: teamFullName,
              feature,
              player_id: playerId,
            });
            const defensiveData = await fetchData(`team-impact/get_team_impact/?${defensiveParams.toString()}`, token);
            defensiveResults[playerId] = defensiveData?.impact_allowed_value ? parseFloat(defensiveData.impact_allowed_value.toFixed(1)) : null;
          } catch (error) {
            console.error(`Error fetching impact for player ${getPlayerId(player.player_id)}:`, error);
            offensiveResults[getPlayerId(player.player_id)] = null;
            defensiveResults[getPlayerId(player.player_id)] = null;
          }
        }

        setOffensiveImpacts(offensiveResults);
        setDefensiveImpacts(defensiveResults);
      }
    };

    fetchImpacts();
  }, [injuredPlayers, token, selectedTeam]);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsEditing(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleTeamChange = (team) => {
    setSelectedTeam(team);
    setIsEditing(false);
  };

  const renderImpactRow = () => {
    return injuredPlayers.map((player, index) => {
      const playerId = getPlayerId(player.player_id);
      const offensiveImpact = offensiveImpacts[playerId];
      const defensiveImpact = defensiveImpacts[playerId];

      return (
        <tr key={index}>
          <td>{player.player_id || 'N/A'}</td>
          <td>
            <span
              className={`status-indicator ${
                player.status === 'Out' ? 'status-out' : player.status === 'Day-To-Day' ? 'status-day-to-day' : ''
              }`}
            ></span>
            {player.status || 'N/A'}
          </td>
          <td>{offensiveImpact !== null && offensiveImpact !== undefined ? (offensiveImpact > 0 ? `+${offensiveImpact} pts` : `${offensiveImpact} pts`) : "No Impact"}</td>
          <td>{defensiveImpact !== null && defensiveImpact !== undefined ? (defensiveImpact > 0 ? `+${defensiveImpact} pts allowed` : `${defensiveImpact} pts allowed`) : "No Impact"}</td>
        </tr>
      );
    });
  };

  return (
    <div className="matchup-injury-table-container" style={{ backgroundColor: 'white' }}>
      <div className="matchup-injury-table-header" ref={wrapperRef} style={{ color: 'black' }}>
        <img src={selectedTeamLogo} alt="Team Logo" className="matchup-injury-table-logo" />
        <div className="matchup-injury-table-title">Matchup Injury Report</div>
        <div className="matchup-injury-table-selected-team-container" onClick={() => setIsEditing(!isEditing)}>
          <div className="matchup-injury-table-selected-team">{selectedTeam || 'Select team...'}</div>
          <div className="matchup-injury-table-dropdown-arrow">▼</div>
        </div>
        {isEditing && (
          <div className="matchup-injury-table-autocomplete-container">
            {teamOptions.map((team, index) => (
              <div
                key={index}
                className="matchup-injury-table-autocomplete-item"
                onClick={() => handleTeamChange(team)}
              >
                {team}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="matchup-injury-table-content">
        <table className="matchup-injury-table-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th>Offensive Impact</th>
              <th>Defensive Impact</th>
            </tr>
          </thead>
          <tbody>
            {injuredPlayers.length > 0 ? renderImpactRow() : (
              <tr>
                <td colSpan="4">No injury data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MatchupInjuryReportTable;
