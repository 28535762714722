import React, { createContext } from 'react';
import { Auth0Provider as Auth0ReactProvider, useAuth0 } from '@auth0/auth0-react';

const Auth0Context = createContext(null);

export const Auth0Provider = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  if (!domain || !clientId || !audience) {
    console.error("Auth0 environment variables are missing.");
    return null; // Optionally handle this case more gracefully
  }

  return (
    <Auth0ReactProvider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: audience,
      }}
    >
      <Auth0Context.Provider value={{}}>
        {children}
      </Auth0Context.Provider>
    </Auth0ReactProvider>
  );
};

export const useAuth0Context = () => {
  const {
    isAuthenticated,
    isLoading,
    user,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
  } = useAuth0();

  return {
    isAuthenticated,
    isLoading,
    user,
    token: async () => {
      try {
        return await getAccessTokenSilently();
      } catch (error) {
        return null;
      }
    },
    login: loginWithRedirect,
    logout: () => logout({ returnTo: window.location.origin }),
  };
};
