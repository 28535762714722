import React, { useState, useEffect, useRef } from 'react';
import '../main_dashboard_components/InjuryReportTable.css';
import { usePlayerColors } from '../main_dashboard_components/PlayerColorContext';
import { combinedInfo } from '../components/CombinedInfo';
import { fetchData } from "../api";
import { useKeycloak } from '../KeycloakProvider';
import teamNameToFullName from '../components/TeamNameToFullName';
import { getPlayerId } from '../components/PlayerLookup';

const TeamInjuryReportTable = ({ selectedFeature }) => {
  const { selectedPlayer, teamColors } = usePlayerColors();
  const { token } = useKeycloak();

  const [isEditing, setIsEditing] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [injuredPlayers, setInjuredPlayers] = useState([]);
  const [impactData, setImpactData] = useState({});
  const [teamOptions, setTeamOptions] = useState([]);
  const [selectedTeamLogo, setSelectedTeamLogo] = useState('/default-logo.png');
  const [loading, setLoading] = useState(true);
  const wrapperRef = useRef(null);

  const featureMap = {
    "Total": "pts",
    "REB": "reb",
    "AST": "ast",
    "BLK": "blk",
    "STL": "stl",
    "Pts+Ast": "pts+ast",
    "Pts+Reb": "pts+reb",
    "Ast+Reb": "ast+reb",
    "Pts+Ast+Reb": "pts+ast+reb"
  };

  const visualFeatureMap = {
    "Total": "Points",
    "AST": "Assists",
    "REB": "Rebounds",
    "BLK": "Blocks",
    "STL": "Steals",
    "Pts+Ast": "Points + Assists",
    "Pts+Reb": "Points + Rebounds",
    "Ast+Reb": "Assists + Rebounds",
    "Pts+Ast+Reb": "Points + Assists + Rebounds"
  };

  useEffect(() => {
    if (selectedPlayer) {
      const player = combinedInfo[selectedPlayer];
      if (player) {
        const teamName = player.teamName;
        const opponentName = player.opponentName;
        setTeamOptions([teamName, opponentName]);
        setSelectedTeam(teamName);
        setSelectedTeamLogo(player.teamLogoSrc);
      }
    }
  }, [selectedPlayer]);

  useEffect(() => {
    if (selectedTeam && token) {
      const fetchInjuryReport = async () => {
        try {
          const teamName = teamNameToFullName[selectedTeam];
          if (!teamName) {
            console.error("Invalid team name mapping for:", selectedTeam);
            return;
          }

          const endpoint = `teams/injury_report_view/?team_name=${teamName}`;
          const data = await fetchData(endpoint, token);
          setInjuredPlayers(data || []);
        } catch (error) {
          console.error('Error fetching injury report:', error);
        }
      };

      fetchInjuryReport();
    }
  }, [selectedTeam, token]);

  useEffect(() => {
    const fetchImpactData = async () => {
      if (selectedFeature && token && selectedTeam && injuredPlayers.length > 0) {
        const mappedFeature = featureMap[selectedFeature];
        if (!mappedFeature) {
          console.error("Invalid selectedFeature:", selectedFeature);
          return;
        }

        const results = { ...impactData }; // Retain existing impact data
        const isOpponent = selectedTeam === combinedInfo[selectedPlayer]?.opponentName;
        const teamName = teamNameToFullName[selectedTeam];

        for (const player of injuredPlayers) {
          const playerId = getPlayerId(player.player_id);
          if (results[playerId] !== undefined) continue;

          try {
            const endpoint = `team-impact/get_team_impact/`;
            const params = new URLSearchParams({
              team_name: teamName,
              feature: mappedFeature,
              player_id: playerId
            });

            const data = await fetchData(`${endpoint}?${params.toString()}`, token);
            results[playerId] = isOpponent
              ? data?.impact_allowed_value
              : data?.impact_value;
          } catch (error) {
            console.error(`Error fetching impact for player ID: ${playerId}`, error);
            results[playerId] = null;
          }
        }

        setImpactData(results);
        setLoading(false);
      }
    };

    fetchImpactData();
  }, [injuredPlayers, selectedFeature, selectedTeam, token]);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsEditing(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleTeamChange = (team) => {
    setSelectedTeam(team);
    setIsEditing(false);
  };

  const renderAutocomplete = () => {
    const teamColor = teamColors[0] || '#000';
    const highlightColor = teamColors[1] || '#fff';

    return (
      <div className="autocomplete-injury-table-container" style={{ backgroundColor: teamColor }}>
        {teamOptions.map((team, index) => (
          <div
            key={index}
            className="autocomplete-injury-table-item"
            onClick={() => handleTeamChange(team)}
            style={{ backgroundColor: teamColor, color: 'white' }}
            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = highlightColor)}
            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = teamColor)}
          >
            {team}
          </div>
        ))}
      </div>
    );
  };

  const renderImpactRow = () => {
    const isOpponent = selectedTeam === combinedInfo[selectedPlayer]?.opponentName;

    return injuredPlayers.map((player, index) => {
      const playerId = getPlayerId(player.player_id);
      const impactValue = impactData[playerId];

      const impactText =
        impactValue !== undefined && impactValue !== null
          ? impactValue > 0
            ? `${isOpponent ? "Allow" : "Get"} ${impactValue.toFixed(1)} more ${visualFeatureMap[selectedFeature]}`
            : `${isOpponent ? "Allow" : "Get"} ${Math.abs(impactValue.toFixed(1))} less ${visualFeatureMap[selectedFeature]}`
          : "No impact";

      return (
        <tr key={index}>
          <td>{player.player_id|| "Unknown"}</td>
          <td>{player.status || "N/A"}</td>
          <td>{impactText}</td>
        </tr>
      );
    });
  };

  return (
    <div className="injury-report-container" style={{ backgroundColor: 'white' }}>
      <div className="table-header" ref={wrapperRef} style={{ color: 'black' }}>
        <img src={selectedTeamLogo} alt="Team Logo" className="injury-logo" />
        <div className="title">Injury Report</div>
        <div className="selected-team-container" onClick={() => setIsEditing(!isEditing)}>
          <div className="selected-team">{selectedTeam || "Select team..."}</div>
          <div className="dropdown-arrow">▼</div>
        </div>
        {isEditing && renderAutocomplete()}
      </div>

      <div className="table-container">
        <table className="injury-report-table" style={{ color: 'black' }}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th>Impact</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="3">Loading...</td>
              </tr>
            ) : (
              renderImpactRow()
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TeamInjuryReportTable;
