import { teamsDict, injuryReport } from './TeamInfo';
import { players } from './PlayerInfo';

export const combinedInfo = Object.keys(players).reduce((acc, playerKey) => {
  const player = players[playerKey];
  const team = teamsDict[player.teamName];
  const opponent = teamsDict[player.opponentName];

  
  acc[playerKey] = {
    ...player,
    teamLogoSrc: team ? team.logo : "/default-logo.png",
    markerColor: team && team.colors && team.colors.length > 0 ? team.colors[0] : '#000000',
    teamColors: team && team.colors ? team.colors : ['#000000'],
    teamInjuryReport: injuryReport[player.teamName] || [],
    opponentLogoSrc: opponent ? opponent.logo : "/default-logo.png",
    opponentTeamColors: opponent && opponent.colors ? opponent.colors : ['#000000'],
    opponentInjuryReport: injuryReport[player.opponentName] || [],
    venue: team ? team.venue : '',
    location: team ? team.location : '',
    opponentVenue: opponent ? opponent.venue : '',
    opponentLocation: opponent ? opponent.location : '',
    opponentName: opponent ? player.opponentName : 's'
  };

  return acc;
}, {});
