// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useKeycloak, AuthWrapper } from './KeycloakProvider';
import PlayerDashboard from './dashboards/PlayerDashboard';
import TeamDashboard from './dashboards/TeamDashboard';
import PlayerID from './main_dashboard_components/PlayerID';
import { PlayerColorProvider } from './main_dashboard_components/PlayerColorContext';
import Leaderboard from './Leaderboard';
import HomePage from './HomePage';
import SelectedPlayers from './SelectedPlayers';
import HeatMap from './PlayerHeatmap';
import TicketWatcher from './dashboards/TicketWatcher';
import TicketAnalyzer from './dashboards/TicketAnalyzer';
import ModelPerformance from './dashboards/ModelPerformance';
import MatchupDashboard from './dashboards/MatchupDashboard';
import MatchupMenu from './components/MatchupMenu';
import NavigationMenu from './components/NavigationMenu';
import TicketsPage from './dashboards/TicketsPage';
import { Navigate } from 'react-router-dom';
import PlayerFeatureTable from './predictions_components/PlayerFeatureTable';
import PredictionResultsTable from './predictions_components/PredictionResultsTable';
import LiveData from './LiveData.js'
import SubscriptionPage from './paypal_components/SubscriptionPage.js';
const ProtectedRoute = ({ children }) => {
  const { authenticated, loading, keycloak } = useKeycloak();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!authenticated) {
    
    keycloak?.login(); // Trigger login if not authenticated
    return null;
  }

  return children;
};

// Main App component
const App = () => {
  const [showMatchupMenu, setShowMatchupMenu] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      setShowMatchupMenu(window.scrollY <= 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <AuthWrapper>
      <PlayerColorProvider>
        <Router>
          <div className="App">
            <MatchupMenu visible={showMatchupMenu} />
            <NavigationMenu />

            <Routes>
              {/* Public route */}
              <Route path="/" element={<HomePage />} />
              <Route path="/Live" element={<LiveData />} />
              {/* Protected routes */}
              <Route
                path="/PlayerDashboard"
                element={
                  <ProtectedRoute>
                    <PlayerDashboard />
                  </ProtectedRoute>
                }
              />
              <Route
              path="/Subscription"
              element={
                <SubscriptionPage />
              }
            />
              <Route
                path="/Predictions"
                element={
                  <ProtectedRoute>
                    <PlayerFeatureTable />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/Results"
                element={
                  <ProtectedRoute>
                    <PredictionResultsTable />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/MatchupDashboard"
                element={
                  <ProtectedRoute>
                    <MatchupDashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/PlayerDashboard/:playerName"
                element={
                  <ProtectedRoute>
                    <PlayerID />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/TeamDashboard"
                element={
                  <ProtectedRoute>
                    <TeamDashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/Leaderboard"
                element={
                  <ProtectedRoute>
                    <Leaderboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/ModelPerformance"
                element={
                  <ProtectedRoute>
                    <ModelPerformance />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/TicketWatcher"
                element={
                  <ProtectedRoute>
                    <TicketWatcher />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/TicketAnalyzer"
                element={
                  <ProtectedRoute>
                    <TicketAnalyzer />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/SelectedPlayers"
                element={
                  <ProtectedRoute>
                    <SelectedPlayers />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/HeatMap"
                element={
                  <ProtectedRoute>
                    <HeatMap />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/Tickets"
                element={
                  <ProtectedRoute>
                    <TicketsPage />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </div>
        </Router>
      </PlayerColorProvider>
    </AuthWrapper>
  );
};

export default App;
