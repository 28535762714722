import { useAuth0Context } from '../Auth0Provider';
import { useEffect, useState } from 'react';

const useAuth = () => {
  const {
    isAuthenticated,
    isLoading: auth0Loading,
    user,
    token: getToken,
    login,
    logout, // Include logout from Auth0
  } = useAuth0Context();

  const [userInfo, setUserInfo] = useState(null); // Consolidated user info and roles
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAuthDetails = async () => {
      if (!isAuthenticated || userInfo || auth0Loading) {
        // Skip if not authenticated, already have userInfo, or still loading
        setLoading(false);
        return;
      }

      try {
        const token = await getToken();
       

        const response = await fetch(
          `${process.env.REACT_APP_AUTH0_DOMAIN}/userinfo`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          const userInfo = await response.json();
          

          setUserInfo({
            username: userInfo.nickname || userInfo.email,
            roles: userInfo.permissions || [], // Adjust key if roles are stored elsewhere
            rawUser: userInfo, // Store raw user data for additional use
          });
        } else {
          console.error("Failed to fetch user info:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching authentication details:", error);
      } finally {
        setLoading(false); // End loading once user info is fetched or an error occurs
      }
    };

    fetchAuthDetails();
  }, [isAuthenticated, auth0Loading, getToken, userInfo]);

  return [
    isAuthenticated, // Whether the user is authenticated
    getToken,        // Function to retrieve the current token
    userInfo?.username || "", // Username or email (default to empty string)
    userInfo?.roles || [],    // User roles from Auth0 (default to empty array)
    loading || auth0Loading,  // Overall loading status
    login,           // Function to trigger login
    logout,          // Function to trigger logout
  ];
};

export default useAuth;
