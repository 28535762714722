import React from 'react';
import NavigationMenu from './components/NavigationMenu';

const HomePage = ({ username }) => {
  return (
    <div>
      <div>
        <iframe src="/home_page/landing.html" title="Static Template" style={{ width: '100%', height: '100vh', border: 'none' }}></iframe>
      </div>
    </div>
  );
};

export default HomePage;
