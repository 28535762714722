// SelectedPlayers.js
import React, { useState, useEffect } from 'react';
import './SelectedPlayers.css';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import { fetchData } from '../api';
import { useKeycloak } from '../KeycloakProvider';
import { players } from '../components/PlayerInfo';
import { teamsDict } from '../components/TeamInfo';
import { teamNameToAbbreviation } from '../components/TeamDictionary';

const SelectedPlayers = ({
  playerIds = [],
  selectedMatchup = null,
  addToParlayBuilder,
  removeFromSelectedPlayers,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [playerToRemove, setPlayerToRemove] = useState(null);
  const [playerData, setPlayerData] = useState([]);
  const [editingField, setEditingField] = useState({});
  const { token } = useKeycloak();

  const allPlayerData = [];
  const featureMapShow = {
    'pts':'Points',
    'reb':'Rebounds',
    'ast':'Assists',
    'pts_ast':'Pts+Ast',
    'pts_reb':'Pts+Reb',
    'reb_ast':'Reb+Ast',
    'pts_ast_reb':'Pts+Ast+Reb'
  };

  const [featureFilter, setFeatureFilter] = useState('All');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  useEffect(() => {
    const fetchPlayerData = async () => {
      if (!token) {
        console.error('Token not available');
        return;
      }

      try {
  let data = [];
  let allPlayerData = []; // Ensure this array is declared to gather all player data
 
  if (playerIds.length > 0 && !selectedMatchup) {
    const cachedPlayers = JSON.parse(localStorage.getItem('savedPlayers')) || [];
    data = cachedPlayers.filter(cp => playerIds.includes(cp.playerId)).map(cp => ({
        ...cp,
        featureDisplay: featureMapShow[cp.feature.toLowerCase()] || cp.feature,
        ...players[cp.playerId]
    }));
    

    for (const playerId of playerIds) {
      const player = players[playerId];
      if (!player) continue;

      // Find cached player data
      const cachedPlayer = cachedPlayers.find(p => p.playerId === playerId);
      if (!cachedPlayer) continue;


      const propType = cachedPlayer.featureDisplay || 'Points'; // Default to 'Points' if propType is not available

      // Map propType to feature used in API
      const featureMap = {
        PTS: 'pts',
        REB: 'reb',
        AST: 'ast',
        'Pts+Ast': 'pts_ast',
        'Pts+Reb': 'pts_reb',
        'Reb+Ast': 'reb_ast',
        'Pts+Ast+Reb': 'pts_ast_reb',
      };

      const feature = featureMap[propType];

      if (!feature) continue; // Skip if feature mapping is not found

      const queryParams = new URLSearchParams({
        latest: 'true',
        player_name: player.name,
        feature: feature,
      });

      try {
        const response = await fetchData(
          `player-feature-predictions/filter_predictions/?${queryParams.toString()}`,
          token
        );
        allPlayerData.push(...response.map(item => ({
          ...item,
          playerId: playerId,
        })));
      } catch (fetchError) {
        console.error('Fetch failed for player', playerId, fetchError);
        // Optionally push player with default or empty data
        allPlayerData.push({ playerId, emptyPrediction: true });
      }
    }

    data = allPlayerData;
   
  } else if (selectedMatchup) {
    // Fetch predictions for the matchup using team_abbreviation
    const { homeTeam, awayTeam } = selectedMatchup;

    // Map full team names to abbreviations
    const homeTeamAbbreviation = teamNameToAbbreviation[homeTeam] || 'na';
    const awayTeamAbbreviation = teamNameToAbbreviation[awayTeam] || 'na';

    if (!homeTeamAbbreviation || !awayTeamAbbreviation) {
      console.error('Team abbreviations not found for matchup');
      return;
    }

    // Fetch data for both teams
    const queryParamsHome = new URLSearchParams({
      latest: 'true',
      team_abbreviation: homeTeamAbbreviation,
    });

    const queryParamsAway = new URLSearchParams({
      latest: 'true',
      team_abbreviation: awayTeamAbbreviation,
    });

    const [homeTeamData, awayTeamData] = await Promise.all([
      fetchData(`player-feature-predictions/filter_predictions/?${queryParamsHome.toString()}`, token).catch(err => {
        console.error('Failed to fetch home team data', err);
        return []; // Return empty array on error
      }),
      fetchData(`player-feature-predictions/filter_predictions/?${queryParamsAway.toString()}`, token).catch(err => {
        console.error('Failed to fetch away team data', err);
        return []; // Return empty array on error
      }),
    ]);

    // Combine data
    data = [...homeTeamData, ...awayTeamData];
  }

  setPlayerData(data);
} catch (error) {
  console.error('Failed to fetch player predictions:', error);
  setPlayerData([]);
}
};

fetchPlayerData();
}, [playerIds, selectedMatchup, token]);


  const handleDeleteClick = (playerId) => {
    setPlayerToRemove(playerId);
    setShowModal(true);
  };

  const confirmRemovePlayer = () => {
    removeFromSelectedPlayers(playerToRemove);
    setShowModal(false);
  };

  const closeModal = () => {
    setShowModal(false);
    setPlayerToRemove(null);
  };

  const handleInputChange = (index, field, value) => {
    setPlayerData((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        [field]: value,
      };
      return newData;
    });
  };

  const handleFieldClick = (index, field) => {
    setEditingField({ index, field });
  };

  const handleBlur = () => {
    setEditingField({});
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const filteredPlayerData = playerData.filter(playerItem => {
    if (featureFilter === 'All') {
      return true;
    }
    return featureMapShow[playerItem.feature] === featureFilter;
  });

  const displayPlayerName = (playerName) => {
    if (playerName.length > 16) {
      const [firstName, ...lastNameParts] = playerName.split(' ');
      const lastName = lastNameParts.join(' ');
      return `${firstName[0]}. ${lastName}`;
    }
    return playerName;
  };

  const sortedPlayerData = React.useMemo(() => {
    if (sortConfig.key !== null) {
      return [...filteredPlayerData].sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];
        // Handle special cases
        if (sortConfig.key === 'player_name') {
          aValue = a.player_name.toLowerCase();
          bValue = b.player_name.toLowerCase();
        } else if (sortConfig.key === 'feature_prediction' || sortConfig.key === 'feature_probability') {
          aValue = Number(aValue);
          bValue = Number(bValue);
        } else if (sortConfig.key === 'odds') {
          aValue = parseFloat(aValue);
          bValue = parseFloat(bValue);
        } else if (sortConfig.key === 'game_date') {
          aValue = new Date(`${a.game_date} ${a.game_time}`);
          bValue = new Date(`${b.game_date} ${b.game_time}`);
        }

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    } else {
      return filteredPlayerData;
    }
  }, [filteredPlayerData, sortConfig]);

  return (
    <div className="selected-players-container">
      <div className="selected-players-header">
        <h3 className="selected-players-title">Selected Players</h3>
        <div className="selected-players-filter">
          <label htmlFor="featureFilter">Prop: </label>
          <select
            id="featureFilter"
            value={featureFilter}
            onChange={(e) => setFeatureFilter(e.target.value)}
          >
            <option value="All">All</option>
            <option value="Points">Points</option>
            <option value="Rebounds">Rebounds</option>
            <option value="Assists">Assists</option>
            <option value="Pts+Ast">Pts+Ast</option>
            <option value="Pts+Reb">Pts+Reb</option>
            <option value="Reb+Ast">Reb+Ast</option>
            <option value="Pts+Ast+Reb">Pts+Ast+Reb</option>
          </select>
        </div>
      </div>
      {sortedPlayerData.length > 0 ? (
        <table className="selected-players-table">
          <thead>
            <tr>
              <th onClick={() => handleSort('player_name')}>Players</th>
              <th onClick={() => handleSort('next_opponent')}>Opponent</th>
              <th onClick={() => handleSort('feature_prediction')}>Prediction</th>
              <th onClick={() => handleSort('feature_probability')}>Probability</th>
              <th onClick={() => handleSort('sportsbook')}>Bookie</th>
              <th onClick={() => handleSort('odds')}>Odds</th>
              <th onClick={() => handleSort('over_under')}>Pick</th>
              <th>Add/Remove</th>
            </tr>
          </thead>
          <tbody>
            {sortedPlayerData.map((playerItem, index) => {
              const playerId =
                playerItem.playerId ||
                Object.keys(players).find((key) => players[key].name === playerItem.player_name);
              const player = players[playerId];

              if (!player) return null;

              return (
                <tr key={index}>
                  <td className="selected-players-player-info">
                    <div
                      className="selected-players-logo-container"
                      style={{ backgroundColor: player.teamColors[0] }}
                    >
                      <img
                        src={player.imageSrc}
                        alt={player.name}
                        className="selected-players-player-logo"
                      />
                    </div>
                    <div className="selected-players-details">
                      <span className="selected-players-name">{displayPlayerName(player.name)}</span>
                      <br />
                      <span className="selected-players-points">
                        {featureMapShow[playerItem.feature]} {playerItem.threshold}
                      </span>
                    </div>
                  </td>
                  <td>
                    {playerItem.next_opponent}
                    <br />
                    {player.home_or_away === 1 ? 'Home' : 'Away'}
                  </td>
                  <td>{Math.round(playerItem.feature_prediction)}</td>
                  <td>{(playerItem.feature_probability * 100).toFixed(1)}%</td>
                  <td>{playerItem.sportsbook}</td>
                  <td>
                    {editingField.index === index && editingField.field === 'odds' ? (
                      <input
                        type="text"
                        value={playerItem.odds}
                        onChange={(e) => handleInputChange(index, 'odds', e.target.value)}
                        onBlur={handleBlur}
                        autoFocus
                        className="selected-players-input"
                      />
                    ) : (
                      <span onClick={() => handleFieldClick(index, 'odds')}>{playerItem.odds}</span>
                    )}
                  </td>
                  <td>
                    {editingField.index === index && editingField.field === 'over_under' ? (
                      <select
                        value={playerItem.over_under}
                        onChange={(e) => handleInputChange(index, 'over_under', e.target.value)}
                        onBlur={handleBlur}
                        autoFocus
                        className="selected-players-select"
                      >
                        <option value="Over">Over</option>
                        <option value="Under">Under</option>
                      </select>
                    ) : (
                      <span onClick={() => handleFieldClick(index, 'over_under')}>
                        {playerItem.over_under}
                      </span>
                    )}
                  </td>
              
                  <td className="selected-players-add-remove-buttons">
                    <button
                      className="selected-players-add-btn"
                      onClick={() => addToParlayBuilder({ ...player, ...playerItem })}
                    >
                      <FaPlus />
                    </button>
                    {removeFromSelectedPlayers && (
                      <button
                        className="selected-players-remove-btn"
                        onClick={() => handleDeleteClick(playerId)}
                      >
                        <FaTrashAlt />
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <p>No players available.</p>
      )}

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Are you sure you want to remove this player?</h3>
            <div className="modal-buttons">
              <button className="confirm-btn" onClick={confirmRemovePlayer}>
                Yes
              </button>
              <button className="cancel-btn" onClick={closeModal}>
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectedPlayers;
