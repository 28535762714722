// PlayerThresholdData.js
import { useEffect } from 'react';
import { fetchData } from '../api';  // Your utility function for fetching data
import { useKeycloak } from '../KeycloakProvider';  // For secure API calls
import { usePlayerColors } from '../main_dashboard_components/PlayerColorContext';  // Import the context

// Map selected features to market fields in the API
const featureToMarketMap = {
  'Total': 'player_points',
  'AST': 'player_assists',
  'REB': 'player_rebounds',
  'STL': 'player_steals',
  'BLK': 'player_blocks',
  'Pts+Ast': 'player_points_assists',
  'Pts+Reb': 'player_points_rebounds',
  'Ast+Reb': 'player_rebounds_assists',
  'Pts+Ast+Reb': 'player_points_rebounds_assists',
  // Add other mappings as necessary
};

const useFetchPlayerThresholds = (playerName) => {
  const { setThresholds } = usePlayerColors();  // Get setThresholds from context
  const { token } = useKeycloak();

  useEffect(() => {
    if (playerName && token) {
      const fetchThresholds = async () => {
        const entries = {};
        const fetchPromises = Object.entries(featureToMarketMap).map(async ([feature, market]) => {
          try {
            const endpoint = `player_props/get_most_common_threshold/?player_name=${encodeURIComponent(playerName)}&market=${encodeURIComponent(market)}`;
            const data = await fetchData(endpoint, token);
            if (data && data.threshold !== undefined) {
              entries[feature] = data.threshold;
            } else {
              entries[feature] = null;  // Or default value
            }
          } catch (error) {
    
            entries[feature] = null;  // Or default value
          }
        });
        await Promise.all(fetchPromises);
        // Set the thresholds in the context
        setThresholds(prevThresholds => ({
          ...prevThresholds,
          [playerName]: entries,
        }));
      };

      fetchThresholds();
    }
  }, [playerName, token, setThresholds]);  // dependencies

};

export default useFetchPlayerThresholds;
