import { useState, useEffect, useRef, useMemo } from 'react';
import { fetchData } from '../../api';  // Import fetchData function
import { useKeycloak } from '../../KeycloakProvider';  // Import Keycloak context for token

const useFetchTeamL15TableData = (selectedTeam, selectedFeature) => {
  const [rollingData, setRollingData] = useState({});
  const dataCache = useRef({});
  const { token } = useKeycloak();  // Get the token from Keycloak

  const featureMetrics = useMemo(() => ({
    Total: [
      'team_pts_mean_rolling_5_games', 'team_pts_mean_rolling_15_games',
      'team_fgm_mean_rolling_5_games', 'team_fgm_mean_rolling_15_games',
      'team_fga_mean_rolling_5_games', 'team_fga_mean_rolling_15_games',
      'team_fg3m_mean_rolling_5_games', 'team_fg3m_mean_rolling_15_games',
      'team_fg3a_mean_rolling_5_games', 'team_fg3a_mean_rolling_15_games',
      'team_ftm_mean_rolling_5_games', 'team_ftm_mean_rolling_15_games',
      'team_fta_mean_rolling_5_games', 'team_fta_mean_rolling_15_games',
      'team_TS_percent_mean_5_games', 'team_TS_percent_mean_15_games',
    ],
    AST: [
      'team_ast_mean_rolling_5_games', 'team_ast_mean_rolling_15_games',
      'team_secondaryassists_mean_rolling_5_games', 'team_secondaryassists_mean_rolling_15_games',
      'team_freethrowassists_mean_rolling_5_games', 'team_freethrowassists_mean_rolling_15_games',
      'team_passes_mean_rolling_5_games', 'team_passes_mean_rolling_15_games',
      'team_touches_mean_rolling_5_games', 'team_touches_mean_rolling_15_games'
    ],
    REB: [
      'team_reb_mean_rolling_5_games', 'team_reb_mean_rolling_15_games',
      'team_oreb_mean_rolling_5_games', 'team_oreb_mean_rolling_15_games',
      'team_dreb_mean_rolling_5_games', 'team_dreb_mean_rolling_15_games',
      'team_reboundchancesdefensive_mean_rolling_5_games', 'team_reboundchancesdefensive_mean_rolling_15_games',
      'team_reboundchancesoffensive_mean_rolling_5_games', 'team_reboundchancesoffensive_mean_rolling_15_games',
      'team_reboundchancestotal_mean_rolling_5_games', 'team_reboundchancestotal_mean_rolling_15_games'
    ],
    BLK: [
      'team_blk_mean_rolling_5_games', 'team_blk_mean_rolling_15_games',
      'team_blka_mean_rolling_5_games', 'team_blka_mean_rolling_15_games',
      'team_stl_mean_rolling_5_games', 'team_stl_mean_rolling_15_games',
    ],
    STL: [
      'team_blk_mean_rolling_5_games', 'team_blk_mean_rolling_15_games',
      'team_blka_mean_rolling_5_games', 'team_blka_mean_rolling_15_games',
      'team_stl_mean_rolling_5_games', 'team_stl_mean_rolling_15_games',
    ],
    'Pts+Ast': [
      'team_pts_mean_rolling_5_games', 'team_pts_mean_rolling_15_games',
      'team_ast_mean_rolling_5_games', 'team_ast_mean_rolling_15_games',
      'team_fg3m_mean_rolling_5_games', 'team_fg3m_mean_rolling_15_games',
      'team_fg3a_mean_rolling_5_games', 'team_fg3a_mean_rolling_15_games',
      'team_fgm_mean_rolling_5_games', 'team_fgm_mean_rolling_15_games',
      'team_fga_mean_rolling_5_games', 'team_fga_mean_rolling_15_games',
      'team_ftm_mean_rolling_5_games', 'team_ftm_mean_rolling_15_games',
      'team_fta_mean_rolling_5_games', 'team_fta_mean_rolling_15_games',
      'team_TS_percent_mean_5_games', 'team_TS_percent_mean_15_games',
    ],
    'Ast+Reb': [
      'team_ast_mean_rolling_5_games', 'team_ast_mean_rolling_15_games',
      'team_reb_mean_rolling_5_games', 'team_reb_mean_rolling_15_games',
      'team_oreb_mean_rolling_5_games', 'team_oreb_mean_rolling_15_games',
      'team_dreb_mean_rolling_5_games', 'team_dreb_mean_rolling_15_games',
      'team_reboundchancestotal_mean_rolling_5_games', 'team_reboundchancestotal_mean_rolling_15_games',
      'team_passes_mean_rolling_5_games', 'team_passes_mean_rolling_15_games',
      'team_touches_mean_rolling_5_games', 'team_touches_mean_rolling_15_games',
    ],
    'Pts+Reb': [
      'team_pts_mean_rolling_5_games', 'team_pts_mean_rolling_15_games',
      'team_reb_mean_rolling_5_games', 'team_reb_mean_rolling_15_games',
      'team_fgm_mean_rolling_5_games', 'team_fgm_mean_rolling_15_games',
      'team_fga_mean_rolling_5_games', 'team_fga_mean_rolling_15_games',
      'team_fg3m_mean_rolling_5_games', 'team_fg3m_mean_rolling_15_games',
      'team_ftm_mean_rolling_5_games', 'team_ftm_mean_rolling_15_games',
      'team_fta_mean_rolling_5_games', 'team_fta_mean_rolling_15_games',
      'team_fg3a_mean_rolling_5_games', 'team_fg3a_mean_rolling_15_games',
      'team_oreb_mean_rolling_5_games', 'team_oreb_mean_rolling_15_games',
      'team_dreb_mean_rolling_5_games', 'team_dreb_mean_rolling_15_games',
    ],
    'Pts+Ast+Reb': [
      'team_pts_mean_rolling_5_games', 'team_pts_mean_rolling_15_games',
      'team_ast_mean_rolling_5_games', 'team_ast_mean_rolling_15_games',
      'team_reb_mean_rolling_5_games', 'team_reb_mean_rolling_15_games',
      'team_ftm_mean_rolling_5_games', 'team_ftm_mean_rolling_15_games',
      'team_fta_mean_rolling_5_games', 'team_fta_mean_rolling_15_games',
      'team_fg3m_mean_rolling_5_games', 'team_fg3m_mean_rolling_15_games',
      'team_fg3a_mean_rolling_5_games', 'team_fg3a_mean_rolling_15_games',
      'team_TS_percent_mean_5_games', 'team_TS_percent_mean_15_games',
      'team_fgm_mean_rolling_5_games', 'team_fgm_mean_rolling_15_games',
      'team_fga_mean_rolling_5_games', 'team_fga_mean_rolling_15_games',
    ]
  }), []);

  useEffect(() => {
    if (selectedTeam && selectedFeature && token) {
      const cacheKey = `${selectedTeam}-${selectedFeature}-rolling`;

      if (!dataCache.current[cacheKey]) {


        const metrics = featureMetrics[selectedFeature] || [];
        const queryString = metrics.map(metric => `metrics=${metric}`).join('&');
        const endpoint = `teams/filter_by_team/?team_abbreviation=${selectedTeam}&${queryString}&x=1`;

        fetchData(endpoint, token)
          .then(data => {
            if (data.length === 0) {
              console.error('No data received');
              return;
            }

            const newRollingData = {};
            metrics.forEach(metric => {
              newRollingData[metric] = data[0][metric];
            });

            // Calculate shooting percentages for summation features including PTS
            if (['Pts+Ast', 'Pts+Reb', 'Pts+Ast+Reb'].includes(selectedFeature)) {
              ['5', '15'].forEach(games => {
                newRollingData[`team_fg_percent_mean_rolling_${games}_games`] = (
                  (parseFloat(newRollingData[`team_fgm_mean_rolling_${games}_games`]) / parseFloat(newRollingData[`team_fga_mean_rolling_${games}_games`])) * 100
                ).toFixed(2);
                newRollingData[`team_fg3_percent_mean_rolling_${games}_games`] = (
                  (parseFloat(newRollingData[`team_fg3m_mean_rolling_${games}_games`]) / parseFloat(newRollingData[`team_fg3a_mean_rolling_${games}_games`])) * 100
                ).toFixed(2);
                newRollingData[`team_ft_percent_mean_rolling_${games}_games`] = (
                  (parseFloat(newRollingData[`team_ftm_mean_rolling_${games}_games`]) / parseFloat(newRollingData[`team_fta_mean_rolling_${games}_games`])) * 100
                ).toFixed(2);
              });
            }

            dataCache.current[cacheKey] = newRollingData;
            setRollingData(newRollingData);
          })
          .catch(error => {
            console.error('Error fetching rolling data:', error);
          });
      } else {
        setRollingData(dataCache.current[cacheKey]);
      }
    }
  }, [selectedTeam, selectedFeature, token, featureMetrics]);

  return rollingData;
};

export default useFetchTeamL15TableData;
