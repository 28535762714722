import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../api";
import { useKeycloak } from "../KeycloakProvider";
import { usePlayerColors } from "../main_dashboard_components/PlayerColorContext";
import "./PlayerFeatureTable.css";
import { getPlayerId } from "../components/PlayerLookup";
const PlayerFeatureTable = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState(() => {
    const savedFilters = localStorage.getItem("playerFeatureFilters");
    return savedFilters
      ? JSON.parse(savedFilters)
      : {
          playerName: "",
          featureProbability: "",
          feature: "",
          teamAbbreviation: "",
          opponent: "",
        };
  });
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const { token, roles } = useKeycloak(); // Access Keycloak roles
  const { setSelectedPlayer } = usePlayerColors();
  const navigate = useNavigate();
  const isPremiumUser = roles.includes("premium"); // Check if user is a premium user
  console.log('roles',roles);
  const featureOptions = [
    { label: "Points", value: "pts" },
    { label: "Assists", value: "ast" },
    { label: "Rebounds", value: "reb" },
    { label: "Points+Assists", value: "pts_ast" },
    { label: "Points+Rebounds", value: "pts_reb" },
    { label: "Assists+Rebounds", value: "ast_reb" },
    { label: "Points+Assists+Rebounds", value: "pts_ast_reb" },
  ];

  useEffect(() => {
    const fetchPlayerData = async () => {
      const queryParams = new URLSearchParams({ latest: "true" });
      if (filters.feature) queryParams.append("feature", filters.feature);

      const endpoint = `player-feature-predictions/filter_predictions/?${queryParams.toString()}`;
      try {
        const response = await fetchData(endpoint, token);
        setData(response);
        setFilteredData(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (token && isPremiumUser) fetchPlayerData();
  }, [token, filters.feature, isPremiumUser]);

  const handlePlayerClick = (playerId) => {
    setSelectedPlayer(getPlayerId(playerId));
    navigate("/PlayerDashboard");
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    const updatedFilters = {
      ...filters,
      [name]: value,
    };
    setFilters(updatedFilters);
    localStorage.setItem("playerFeatureFilters", JSON.stringify(updatedFilters));
  };

  useEffect(() => {
    let filtered = [...data];

    if (filters.playerName) {
      filtered = filtered.filter((row) =>
        row.player_name?.toLowerCase().includes(filters.playerName.toLowerCase())
      );
    }

    if (filters.featureProbability) {
      const minProbability = parseFloat(filters.featureProbability) / 100;
      filtered = filtered.filter((row) =>
        row.feature_probability && row.feature_probability > minProbability
      );
    }

    if (filters.teamAbbreviation) {
      filtered = filtered.filter((row) =>
        row.team_abbreviation?.toLowerCase().includes(filters.teamAbbreviation.toLowerCase())
      );
    }

    if (filters.opponent) {
      filtered = filtered.filter((row) =>
        row.next_opponent?.toLowerCase().includes(filters.opponent.toLowerCase())
      );
    }

    if (sortConfig.key) {
      filtered.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === "asc" ? -1 : 1;
        if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
      });
    }

    setFilteredData(filtered);
  }, [filters, data, sortConfig]);

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  const handleSubscribe = () => {
    navigate("/Subscription"); // Navigate to the subscription page
  };

  if (!isPremiumUser) {
    return (
      <div className="player-feature-table-container">
        <h1>This page is only for premium users</h1>
        <button
          className="subscribe-button"
          onClick={handleSubscribe}
        >
          Subscribe Now
        </button>
      </div>
    );
  }

  return (
    <div className="player-feature-table-container">
      <h1>Tonight's Predictions</h1>

      <div className="filters">
        <input
          type="text"
          placeholder="Player Name"
          name="playerName"
          value={filters.playerName}
          onChange={handleFilterChange}
        />
        <input
          type="number"
          placeholder="Probability >"
          name="featureProbability"
          value={filters.featureProbability}
          onChange={handleFilterChange}
        />
        <select
          name="feature"
          value={filters.feature}
          onChange={handleFilterChange}
        >
          <option value="">All Features</option>
          {featureOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <input
          type="text"
          placeholder="Team Abbreviation"
          name="teamAbbreviation"
          value={filters.teamAbbreviation}
          onChange={handleFilterChange}
        />
        <input
          type="text"
          placeholder="Opponent"
          name="opponent"
          value={filters.opponent}
          onChange={handleFilterChange}
        />
      </div>

      {filteredData.length > 0 ? (
        <table className="player-feature-table">
          <thead>
      <tr>
        {[
          { key: "player_name", label: "Player Name" },
          { key: "team_abbreviation", label: "Team" },
          { key: "next_opponent", label: "Opponent" },
          { key: "feature", label: "Prop" },
          { key: "sportsbook", label: "Sportsbook" },
          { key: "threshold", label: "Threshold" },
          { key: "odds", label: "Odds" },
          { key: "feature_prediction", label: "Prediction" },
          { key: "feature_probability", label: "Probability" },
          { key: "over_under", label: "Bet" },
          { key: "game_date", label: "Game Date" },
        ].map((header) => (
          <th
            key={header.key}
            onClick={() => handleSort(header.key)}
            className={sortConfig.key === header.key ? sortConfig.direction : ""}
          >
            {header.label}
          </th>
        ))}
      </tr>
    </thead>

          <tbody>
            {filteredData.map((row, index) => (
              <tr key={index}>
                <td
                  className="clickable"
                  onClick={() => handlePlayerClick(row.player_name)}
                >
                  {row.player_name}
                </td>
                <td>{row.team_abbreviation}</td>
                <td>{row.next_opponent}</td>
                <td>{row.feature}</td>
                <td>{row.sportsbook}</td>
                <td>{row.threshold}</td>
                <td>{row.odds}</td>
                <td>{Math.round(row.feature_prediction)}</td>
                <td>{(row.feature_probability * 100).toFixed(1)}%</td>
                <td>{row.over_under}</td>
                <td>{row.game_date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No data available to display.</p>
      )}
    </div>
  );
};

export default PlayerFeatureTable;
