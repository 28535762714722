import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { usePlayerColors } from '../main_dashboard_components/PlayerColorContext';
import { teamsDict } from "../components/TeamInfo";
import { fetchData } from '../api'; // Import fetchData for API calls
import { useKeycloak } from '../KeycloakProvider'; // Import the Keycloak context
import "./TeamCard.css";

const TeamCard = ({ className = "" }) => {
  const [inputValue, setInputValue] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const {
    teamColors,
    setTeamColors,
    teamLogoSrc,
    setTeamLogoSrc,
    setOpponentName,
    updateTeamSelection,
    teamHistory,
    selectedTeam,
    setSelectedTeam
  } = usePlayerColors();
  const [teamRecord, setTeamRecord] = useState(null); // State for team record
  const { token } = useKeycloak(); // Get the token from Keycloak
  const wrapperRef = useRef(null);
 
  useEffect(() => {
    if (!selectedTeam) {
      const initialTeam = teamHistory.length ? teamHistory[teamHistory.length - 1] : 'Lakers';

      setSelectedTeam(initialTeam);
      setTeamColors(teamsDict[initialTeam].colors);
      setTeamLogoSrc(teamsDict[initialTeam].logo);
      setOpponentName(teamsDict[initialTeam].name);
      updateTeamSelection(initialTeam);
    }
  }, [selectedTeam, teamHistory, setTeamColors, setTeamLogoSrc, setOpponentName, updateTeamSelection, setSelectedTeam]);

  useEffect(() => {
    const fetchTeamStats = async () => {
      if (selectedTeam && teamsDict[selectedTeam]) {
        const teamId = teamsDict[selectedTeam].team_id;
        const metrics = ['w', 'l'];
        const queryString = metrics.map(metric => `metrics=${metric}`).join('&');
        const endpoint = `team_overall_stats/?team_id=${teamId}&${queryString}`;

        try {
          const data = await fetchData(endpoint, token);
          setTeamRecord(`${data.w}-${data.l}`);
        } catch (error) {
          console.error('Error fetching team stats:', error);
        }
      }
    };

    fetchTeamStats();
  }, [selectedTeam, token]);

  const handleTeamChange = (value) => setInputValue(value);

  const handleTeamSelect = (team) => {
    setSelectedTeam(team);
    setTeamColors(teamsDict[team].colors);
    setTeamLogoSrc(teamsDict[team].logo);
    setOpponentName(teamsDict[team].name);
    updateTeamSelection(team);
    setInputValue("");
    setIsEditing(false);
  };

  const filteredTeams = Object.keys(teamsDict)
    .filter((key) => key.toLowerCase().includes(inputValue.toLowerCase()))
    .sort((a, b) => a.localeCompare(b))
    .slice(0, 15);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsEditing(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleItemClick = (team) => {
    handleTeamSelect(team);
    const items = document.querySelectorAll('.team-card-autocomplete-item');
    items.forEach(item => item.classList.remove('clicked'));
    document.getElementById(team).classList.add('clicked');
  };

  const teamColor = teamColors[0] || '#000';
  const highlightColor = teamColors[1] || '#fff';
  const backgroundColor = teamColors[2] || '#333';

  return (
    <div className={`team-card ${className}`} style={{ background: `linear-gradient(to bottom, ${teamColor} 10%, ${highlightColor} 150%)` }}>
      <div className="team-card-inner">
        <div className="team-card-header" ref={wrapperRef}>
          <div className="team-card-selected-container" onClick={() => setIsEditing(!isEditing)}>
            <div className="team-card-selected" style={{ color: 'white' }}>
              <span className="team-card-name">{selectedTeam}</span>
            </div>
            <div className="team-card-dropdown-arrow" style={{ color: 'white' }}>▼</div>
          </div>
          {isEditing && (
            <div className="team-card-autocomplete-container" style={{ backgroundColor: teamColor }}>
              <div className="team-card-autocomplete-item" style={{ backgroundColor: teamColor, color: 'white' }}>
                <input
                  type="text"
                  placeholder="Search team..."
                  value={inputValue}
                  onChange={(e) => handleTeamChange(e.target.value)}
                  className="team-card-autocomplete-search-input"
                  style={{ backgroundColor: teamColor, color: 'white' }}
                  autoFocus
                />
              </div>
              <div className="team-card-autocomplete-scroll-container" style={{ backgroundColor: teamColor }}>
                {filteredTeams.map((team) => (
                  <div
                    key={team}
                    id={team}
                    className="team-card-autocomplete-item"
                    onClick={() => handleItemClick(team)}
                    style={{ backgroundColor: teamColor, color: 'white' }}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = highlightColor}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = teamColor}
                  >
                    {team}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="team-card-logo-container">
          <img className="team-card-logo" alt="" src={teamLogoSrc || ''} />
        </div>
        <div className="team-card-middle-line-container">
          <div className="team-card-middle-line" style={{ backgroundColor: teamColor }}>
            <div className="team-card-record">{teamRecord || 'Loading...'}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

TeamCard.propTypes = {
  className: PropTypes.string,
};

export default TeamCard;
