import React, { useEffect, useRef, useState } from "react";
import { fetchData } from "../api"; // import fetchData from your api.js
import { useKeycloak } from "../KeycloakProvider"; // Assuming this provides 'token'
import "./SubscriptionPage.css";

// Environment variables
const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;
const PAYPAL_PLAN_ID = process.env.REACT_APP_PAYPAL_PLAN_ID;

const SubscriptionPage = () => {
  const paypalRef = useRef();
  const { token } = useKeycloak();
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [expirationDate, setExpirationDate] = useState(null);
  const [loadingSubscription, setLoadingSubscription] = useState(true);

  const auth0_id = token ? JSON.parse(atob(token.split(".")[1])).sub : null;

  const savePurchase = async (purchaseData) => {
    try {
      const response = await fetchData(
        `subscription/save_purchase/`,
        token,
        "POST",
        purchaseData
      );
      console.log("Purchase saved successfully:", response);
    } catch (error) {
      console.error("Error saving purchase:", error.message);
      alert(`Error saving purchase: ${error.message}`);
    }
  };

  const cancelSubscription = async () => {
    try {
      const response = await fetchData(
        `subscription/cancel_subscription/`,
        token,
        "POST",
        { auth0_id }
      );
      if (response.status === "success") {
        alert("Subscription canceled successfully.");
        setSubscriptionStatus("cancelled_but_valid_until");
      } else {
        alert(`Error canceling subscription: ${response.message}`);
      }
    } catch (error) {
      console.error("Error canceling subscription:", error.message);
      alert("Error canceling subscription. Please try again.");
    }
  };

  const reactivateSubscription = async () => {
    try {
      const response = await fetchData(
        `subscription/reactivate_subscription/`,
        token,
        "POST",
        { auth0_id }
      );
      if (response.status === "success") {
        alert("Subscription reactivated successfully.");
        setSubscriptionStatus("active");
      } else {
        alert(`Error reactivating subscription: ${response.message}`);
      }
    } catch (error) {
      console.error("Error reactivating subscription:", error.message);
      alert("Error reactivating subscription. Please try again.");
    }
  };

  const checkSubscriptionStatus = async () => {
    if (!auth0_id) {
      console.warn("No auth0_id available for subscription status check.");
      setIsSubscribed(false);
      setLoadingSubscription(false);
      return;
    }

    try {
      setLoadingSubscription(true);
      const statusData = await fetchData(
        `subscription/subscription_status/?auth0_id=${encodeURIComponent(
          auth0_id
        )}`,
        token
      );
      console.log("Status data:", statusData);

      if (statusData) {
        if (statusData.status === "active") {
          setIsSubscribed(true);
          setSubscriptionStatus("active");
          setExpirationDate(statusData.next_billing_date || null);
        } else if (statusData.status === "cancelled_but_valid_until") {
          setIsSubscribed(true);
          setSubscriptionStatus("cancelled_but_valid_until");
          setExpirationDate(statusData.expires_at || null);
        } else {
          setIsSubscribed(false);
        }
      } else {
        setIsSubscribed(false);
      }
    } catch (error) {
      console.error("Error checking subscription status:", error.message);
      setIsSubscribed(false);
    } finally {
      setLoadingSubscription(false);
    }
  };

  useEffect(() => {
    if (token && auth0_id) {
      checkSubscriptionStatus();
    } else {
      setIsSubscribed(false);
      setLoadingSubscription(false);
    }
  }, [token, auth0_id]);

  useEffect(() => {
    if (!document.getElementById("paypal-script")) {
      const script = document.createElement("script");
      script.id = "paypal-script";
      script.src = `https://www.paypal.com/sdk/js?client-id=${PAYPAL_CLIENT_ID}&vault=true&intent=subscription`;
      script.async = true;
      script.onload = () => {
        console.log("PayPal script loaded");
        setScriptLoaded(true);
      };
      document.body.appendChild(script);
    } else {
      if (window.paypal) {
        setScriptLoaded(true);
      }
    }
  }, []);

  useEffect(() => {
    if (
      scriptLoaded &&
      window.paypal &&
      isSubscribed === false &&
      !loadingSubscription
    ) {
      try {
        window.paypal
          .Buttons({
            style: {
              shape: "rect",
              color: "gold",
              layout: "vertical",
              label: "subscribe",
            },
            createSubscription: function (data, actions) {
              return actions.subscription.create({
                plan_id: PAYPAL_PLAN_ID,
              });
            },
            onApprove: (data) => {
              const subscriptionID = data.subscriptionID;
              const purchaseData = {
                purchase_type: "Subscription",
                paypal_id: subscriptionID,
                auth0_id: auth0_id,
                purchase_date: new Date().toISOString(),
              };

              alert(`Subscription successful! ID: ${subscriptionID}`);
              savePurchase(purchaseData).then(() => {
                setIsSubscribed(true);
                setSubscriptionStatus("active");
              });
            },
            onError: (err) => {
              console.error("PayPal Subscription Error:", err);
              alert("Error with the PayPal subscription. Please try again.");
            },
          })
          .render(paypalRef.current);
      } catch (err) {
        console.error("Error rendering PayPal buttons:", err);
      }
    }
  }, [scriptLoaded, isSubscribed, loadingSubscription]);

  return (
    <div className="subscription-page">
      <div className="subscription-container">
        <h1>Subscribe to Premium</h1>

        {loadingSubscription && <p>Checking subscription status...</p>}

        {!loadingSubscription && isSubscribed === false && (
          <div className="paypal-button-container" ref={paypalRef}></div>
        )}

        {subscriptionStatus === "active" && !loadingSubscription && (
          <div>
            <p>
              You are subscribed! Your subscription renews on{" "}
              <b>{new Date(expirationDate).toLocaleDateString()}</b>.
            </p>
            <button
              className="cancel-subscription-btn"
              onClick={cancelSubscription}
            >
              Cancel Subscription
            </button>
          </div>
        )}

        {subscriptionStatus === "cancelled_but_valid_until" && !loadingSubscription && (
          <div>
            <p>
              Your subscription is cancelled but remains valid until{" "}
              <b>{new Date(expirationDate).toLocaleDateString()}</b>.
            </p>
            <button
              className="reactivate-subscription-btn"
              onClick={reactivateSubscription}
            >
              Reactivate Subscription
            </button>
          </div>
        )}

        {!auth0_id && !loadingSubscription && (
          <p>Please log in before subscribing.</p>
        )}
      </div>
    </div>
  );
};

export default SubscriptionPage;
