import React from 'react';
import { usePlayerColors } from './PlayerColorContext';
import useFetchPositionData from './data/PositionData';
import './PositionDefense.css';
import { players } from '../components/PlayerInfo';

const PositionDefense = ({ selectedFeature, title }) => {
  const positionData = useFetchPositionData(selectedFeature);
  const { selectedPlayer } = usePlayerColors();
  const playerPosition = players[selectedPlayer]?.position; // Get the position of the selected player

  // Determine which positions to highlight based on the selected player's position
  const getHighlightedPositions = (position) => {
    if (position === 'G') return ['PG', 'SG']; // Highlight both PG and SG for G
    if (position === 'F') return ['PF', 'SF']; // Highlight both PF and SF for F
    if (position === 'GF') return ['SG', 'SF']; // Highlight both PF and SF for F
    if (position === 'FC') return ['PF', 'C']; // Highlight both PF and SF for F
    return [position]; // Highlight only the specific position otherwise
  };

  const highlightedPositions = getHighlightedPositions(playerPosition);

  const getColorForRank = (rank) => {
    if (rank >= 1 && rank <= 10) return 'red';
    if (rank >= 11 && rank <= 20) return 'black';
    return 'green';
  };

  const formatRank = (rank) => {
    const j = rank % 10,
      k = rank % 100;
    if (j === 1 && k !== 11) return rank + 'st';
    if (j === 2 && k !== 12) return rank + 'nd';
    if (j === 3 && k !== 13) return rank + 'rd';
    return rank + 'th';
  };

  const getDisplayFeature = (feature) => {
    const featureMap = {
      Total: 'Points',
      'Pts+Ast': 'P+A',
      'Pts+Ast+Reb': 'P+A+R',
      'Pts+Reb': 'P+R',
    };
    return featureMap[feature] || feature;
  };

  if (Object.keys(positionData).length === 0) return <div>Loading...</div>;

  const positions = positionData.Positions || [];
  const featureValues = positionData.FeatureValues || [];
  const ranks = positionData.Ranks || [];

  const desiredOrder = ['PG', 'SG', 'SF', 'PF', 'C'];

  const sortedPositionData = desiredOrder.map((pos) => {
    const index = positions.indexOf(pos);
    return {
      position: pos,
      featureValue: featureValues[index],
      rank: ranks[index],
    };
  });

  return (
    <div className="position-versus-stats-container">
      <div className="position-versus-table-header">
        <div className="position-versus-header-title">
          {title || (
            <div style={{ textAlign: 'left', marginLeft: '2px' }}>
              <div>Position</div>
              <div>Defense</div>
            </div>
          )}
        </div>
        <div className="info-button">
          ?
          <div className="info-text">
            This table shows the defense performance against different positions.
            <br />
            <div className="legend-item legend-hard">
              <span className="legend-color">A</span> Hard (Rank 1-10)
            </div>
            <div className="legend-item legend-neutral">
              <span className="legend-color">A</span> Neutral (Rank 11-20)
            </div>
            <div className="legend-item legend-easy">
              <span className="legend-color">A</span> Easy (Rank 21-30)
            </div>
          </div>
        </div>
      </div>
      <table className="position-stats-table">
        <thead>
          <tr>
            <th>Position</th>
            <th>{getDisplayFeature(selectedFeature)}</th>
          </tr>
        </thead>
        <tbody>
          {sortedPositionData.map(({ position, featureValue, rank }, index) => (
            <tr
              key={index}
              style={{
                backgroundColor: highlightedPositions.includes(position)
                  ? '#f0f0f0'
                  : 'inherit', // Highlight the row if it matches any of the highlighted positions
              }}
            >
              <td>{position}</td>
              <td>
                <span style={{ color: getColorForRank(rank) }}>
                  {featureValue?.toFixed(1)}
                  <span style={{ fontSize: '11px' }}>({formatRank(rank)})</span>
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PositionDefense;
