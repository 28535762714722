import React, { useState } from 'react';
import ReactECharts from 'echarts-for-react';
import './SimilarPlayerPerformance.css';
import { usePlayerColors } from './PlayerColorContext';
import { players } from '../components/PlayerInfo';
import { teamNameToAbbreviation } from '../components/TeamDictionary';
import { useKeycloak } from '../KeycloakProvider';
import { fetchData } from '../api';

const ChartWithBar = ({ values, dates, lineValues, chartKey }) => {
  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'shadow' },
      formatter: (params) => {
        const date = params[0].name;
        const points = params[0].value;
        const line = params[1].value;
        const difference = (points - line).toFixed(2);
        return `Date: ${date}<br/>Feature: ${points}<br/>Line: ${line}<br/>Difference: ${difference}`;
      },
    },
    xAxis: { type: 'category', data: dates, axisLine: { show: false }, axisTick: { show: false }, axisLabel: { show: false } },
    yAxis: { show: false },
    series: [
      { type: 'bar', data: values, itemStyle: { color: (params) => (params.value > lineValues[params.dataIndex] ?  '#008000' : '#ff0000') } },
      { type: 'line', data: lineValues, itemStyle: { color: '#507B58' }, showSymbol: false },
    ],
  };

  return (
    <div className="similar-player-chart-container">
      <ReactECharts option={option} style={{ height: '90px', width: '120px' }} key={chartKey} />
    </div>
  );
};

const SimilarPlayerPerformance = ({ selectedFeature }) => {
  const { selectedPlayer, thresholds } = usePlayerColors(); // Access thresholds
  const [featureData, setFeatureData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { token } = useKeycloak();

  const featureMapping = {
    Total: 'pts',
    'Pts+Ast+Reb': 'pts_ast_reb',
    'Pts+Ast': 'pts_ast',
    'Pts+Reb': 'pts_reb',
    'Ast+Reb': 'ast_reb',
    AST: 'ast',
    REB: 'reb',
    BLK: 'blk',
    STL: 'stl',
  };

  const displayMapping = {
    Total: 'pts',
    'Pts+Ast+Reb': 'P+A+R',
    'Pts+Ast': 'P+A',
    'Pts+Reb': 'P+R',
    'Ast+Reb': 'A+R',
  };

  const requestFeature = featureMapping[selectedFeature] || selectedFeature;
  const displayFeature = displayMapping[selectedFeature] || selectedFeature;

  const fetchLast5GamesForSimilarPlayer = async (similarPlayerId) => {
    const metrics = ['ftm', 'fg2m', 'fg3m', 'game_date', 'min', 'ast', 'reb', 'blk', 'stl'];
    const queryString = metrics.map((metric) => `metrics=${metric}`).join('&');
    const opponentAbbreviation = players[selectedPlayer]?.opponentName
      ? teamNameToAbbreviation[players[selectedPlayer]?.opponentName]
      : '';

    const url = `players/filter_by_player/?player_id=${similarPlayerId}&${queryString}&x=5${
      opponentAbbreviation ? `&opponent=${opponentAbbreviation}` : ''
    }`;

    const data = await fetchData(url, token);

    if (!data.length) {
      console.warn(`No data found for player_id ${similarPlayerId} against ${opponentAbbreviation}`);
      return null;
    }

    const sortedData = data.sort((a, b) => new Date(a.game_date) - new Date(b.game_date));

    const FT = sortedData.map((d) => d.ftm);
    const PT2 = sortedData.map((d) => d.fg2m);
    const PT3 = sortedData.map((d) => d.fg3m);
    const MIN = sortedData.map((d) => d.min);
    const AST = sortedData.map((d) => d.ast);
    const REB = sortedData.map((d) => d.reb);
    const BLK = sortedData.map((d) => d.blk);
    const STL = sortedData.map((d) => d.stl);
    const Dates = sortedData.map((d) => d.game_date);
    const Total = sortedData.map((d) => d.fg2m * 2 + d.fg3m * 3 + d.ftm);

    return {
      FT,
      PT2,
      PT3,
      Total,
      MIN,
      AST,
      REB,
      BLK,
      STL,
      Dates,
    };
  };

  const fetchSimilarPlayers = async () => {
    setLoading(true);
    try {
      const url = `players/get_similar_players/?player_id=${selectedPlayer}&feature=${requestFeature}&x=5`;
      const similarPlayers = await fetchData(url, token);

      const validFeatureData = [];
      for (const player of similarPlayers) {
        if (validFeatureData.length >= 5) break;

        const chartData = await fetchLast5GamesForSimilarPlayer(player.player_id);
        if (chartData) {
          const playerInfo = players[player.player_id];
          if (!playerInfo) {
            console.warn(`No player info found for player_id: ${player.player_id}`);
            continue;
          }
          const lineValue = thresholds[players[selectedPlayer].name][selectedFeature] || 0; // Use threshold if available, default to 0
          const lineValues = Array(chartData.Total.length).fill(lineValue); // Create an array of the same value

          validFeatureData.push({
            player: playerInfo.name,
            image: playerInfo.imageSrc,
            team: playerInfo.teamName,
            teamLogo: playerInfo.teamLogoSrc,
            position: playerInfo.position,
            values: chartData.Total,
            dates: chartData.Dates,
            lineValues, // Use the calculated lineValues
          });
        }
      }

      setFeatureData(validFeatureData);
    } catch (error) {
      console.error('Error fetching similar players:', error);
    } finally {
      setLoading(false);
    }
  };

  const formatPlayerName = (name) => {
    const parts = name.split(' ');
    const firstNameInitial = parts[0][0];
    const lastName = parts.slice(1).join(' ');
    return `${firstNameInitial}. ${lastName}`;
  };

  return (
    <div className="similar-player-performance-container">
      <div className="similar-player-header-container">
        <div className="title-container">
          <h2>Similar Players</h2>
          <h3>VS {players[selectedPlayer]?.opponentName}</h3>
        </div>
        <button onClick={fetchSimilarPlayers} disabled={loading} className="fetch-similar-players-button">
          {loading ? <span className="loading-spinner"></span> : <>Get Players</>}
        </button>
      </div>

      {featureData.length > 0 && (
        <table className="similar-player-performance-table">
          <thead>
            <tr>
              <th className="similar-player-centered-header">Player</th>
              <th className="similar-player-centered-header">{displayFeature}</th>
              <th className="similar-player-centered-header">Min</th>
              <th className="similar-player-centered-header">VS line</th>
            </tr>
          </thead>
          <tbody>
            {featureData.map((playerData, index) => (
              <tr key={index}>
                <td className="similar-player-centered-cell player-cell">
                  <div className="similar-player-player-info-container">
                    <img
                      src={playerData.image}
                      alt={playerData.player}
                      className="similar-player-performance-player-image"
                    />
                    <img
                      src={playerData.teamLogo}
                      alt={`${playerData.team} logo`}
                      className="similar-player-team-logo"
                    />
                    <div className="similar-player-name-box">
                      <span className="similar-player-performance-player-position">{playerData.position}</span>
                      <div className="player-name-box">
                        <span className="similar-player-performance-player-name">
                          {formatPlayerName(playerData.player)}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="similar-player-centered-cell">
                  {(
                    playerData.values.reduce((a, b) => parseFloat(a) + parseFloat(b), 0) /
                    playerData.values.length
                  ).toFixed(2)}
                </td>
                <td className="similar-player-centered-cell">{playerData.values[0]}</td>
                <td className="similar-player-centered-cell">
                  <ChartWithBar
                    values={playerData.values}
                    dates={playerData.dates}
                    lineValues={playerData.lineValues}
                    chartKey={`${playerData.player}-${selectedFeature}`}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default SimilarPlayerPerformance;
