// PredictionResultsTable.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../api";
import { useKeycloak } from "../KeycloakProvider";
import { usePlayerColors } from "../main_dashboard_components/PlayerColorContext";
import "./PlayerFeatureTable.css";
import "./PredictionResultsTable.css";
import { getPlayerId } from "../components/PlayerLookup";

const PredictionResultsTable = () => {
  const [data, setData] = useState([]); // Full dataset from the API
  const [filteredData, setFilteredData] = useState([]); // Filtered dataset
  const [kpiData, setKpiData] = useState([]); // Data for KPI cards
  const [filters, setFilters] = useState({
    featureProbability: "",
    feature: "",
    teamAbbreviation: "",
    opponent: "",
    betAmount: 100, // Default bet amount set to $100
  });
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const { token } = useKeycloak();
  const { setSelectedPlayer } = usePlayerColors();
  const navigate = useNavigate();

  const featureOptions = [
    { label: "Points", value: "pts" },
    { label: "Assists", value: "ast" },
    { label: "Rebounds", value: "reb" },
    { label: "Pts+Ast", value: "pts_ast" },
    { label: "Pts+Reb", value: "pts_reb" },
    { label: "Ast+Reb", value: "ast_reb" },
    { label: "Pts+Ast+Reb", value: "pts_ast_reb" },
  ];

  useEffect(() => {
    const fetchPlayerData = async () => {
      const queryParams = new URLSearchParams({ latest: "true" });
      // Removed the feature filter from fetching to ensure KPI cards have full data
      // if (filters.feature) queryParams.append("feature", filters.feature);

      const endpoint = `player-prediction-results/filter_predictions/?${queryParams.toString()}`;
      try {
        const response = await fetchData(endpoint, token);
        setData(response);
        setFilteredData(response);
        setKpiData(response); // Initialize KPI data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (token) fetchPlayerData();
  }, [token]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    let filtered = [...data];

    // For KPI cards, apply only probability and betAmount filters
    let kpiFilteredData = [...data];

    if (filters.featureProbability) {
      const minProb = parseFloat(filters.featureProbability);
      filtered = filtered.filter((row) => row.feature_probability * 100 >= minProb);
      kpiFilteredData = kpiFilteredData.filter(
        (row) => row.feature_probability * 100 >= minProb
      );
    }

    // For KPI cards, only apply the probability and betAmount filters
    // For table data, apply all filters
    if (filters.feature) {
      filtered = filtered.filter((row) => row.feature === filters.feature);
      // Do not apply to kpiFilteredData
    }

    if (filters.teamAbbreviation) {
      filtered = filtered.filter((row) =>
        row.team_abbreviation
          ?.toLowerCase()
          .includes(filters.teamAbbreviation.toLowerCase())
      );
    }

    if (filters.opponent) {
      filtered = filtered.filter((row) =>
        row.next_opponent?.toLowerCase().includes(filters.opponent.toLowerCase())
      );
    }

    if (sortConfig.key) {
      filtered.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key])
          return sortConfig.direction === "asc" ? -1 : 1;
        if (a[sortConfig.key] > b[sortConfig.key])
          return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
      });
    }

    setFilteredData(filtered);
    setKpiData(kpiFilteredData);
  }, [filters, data, sortConfig]);

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  const handlePlayerClick = (playerId) => {
    setSelectedPlayer(getPlayerId(playerId));
    navigate("/PlayerDashboard");
  };

  const calculateMetrics = (data, betAmount) => {
    const metrics = featureOptions.reduce(
      (acc, option) => {
        const featureData = data.filter((row) => row.feature === option.value);
        const numGames = featureData.length;
        const totalHits = featureData.filter((row) => row.hit).length;

        const accuracy = numGames > 0 ? (totalHits / numGames) * 100 : 0;
        const profit = featureData.reduce((sum, row) => {
          return sum + (row.hit ? betAmount * (row.odds - 1) : -betAmount);
        }, 0);

        acc[option.value] = {
          label: option.label,
          accuracy: accuracy.toFixed(1),
          profit: Math.round(profit), // Rounded to whole number
          games: numGames,
        };

        return acc;
      },
      { total: { label: "Total", accuracy: 0, profit: 0, games: 0 } }
    );

    const totalHits = data.filter((row) => row.hit).length;
    const totalProfit = data.reduce((sum, row) => {
      return sum + (row.hit ? betAmount * (row.odds - 1) : -betAmount);
    }, 0);

    metrics.total.accuracy =
      data.length > 0 ? ((totalHits / data.length) * 100).toFixed(1) : 0;
    metrics.total.profit = Math.round(totalProfit); // Rounded to whole number
    metrics.total.games = data.length;

    return metrics;
  };

  // Use the KPI data set for metrics to include probability filter but exclude other filters
  const metrics = calculateMetrics(kpiData, filters.betAmount);

  return (
    <div className="player-feature-table-container">
      <h1>Yesterday's Results</h1>

      {/* KPI Cards */}
      <div className="kpi-cards">
        {Object.entries(metrics).map(([key, value]) => (
          <div key={key} className="kpi-card">
            <p>{value.label}</p>
            <p>
              Accuracy:{" "}
              <span className="kpi-card-accuracy-value">{value.accuracy}%</span>
            </p>
            <p>
              Profit:{" "}
              <span
                className={`kpi-card-profit-value ${
                  value.profit >= 0 ? "positive" : "negative"
                }`}
              >
                ${value.profit}
              </span>
            </p>
            <p>Games: {value.games}</p>
          </div>
        ))}
      </div>

      {/* Filters */}
      <div className="filters">
        <input
          type="number"
          placeholder="Bet Amount"
          name="betAmount"
          value={filters.betAmount}
          onChange={handleFilterChange}
        />
        <input
          type="number"
          placeholder="Probability>"
          name="featureProbability"
          min="0"
          max="100"
          value={filters.featureProbability}
          onChange={handleFilterChange}
        />
        <select
          name="feature"
          value={filters.feature}
          onChange={handleFilterChange}
        >
          <option value="">All Features</option>
          {featureOptions.map((opt) => (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </select>
        <input
          type="text"
          placeholder="Team Abbreviation"
          name="teamAbbreviation"
          value={filters.teamAbbreviation}
          onChange={handleFilterChange}
        />
        <input
          type="text"
          placeholder="Opponent"
          name="opponent"
          value={filters.opponent}
          onChange={handleFilterChange}
        />
      </div>

      {/* Table */}
      {filteredData.length > 0 ? (
        <table className="player-feature-table">
          <thead>
            <tr>
              <th onClick={() => handleSort("player_name")}>Player Name</th>
              <th onClick={() => handleSort("team_abbreviation")}>Team</th>
              <th onClick={() => handleSort("next_opponent")}>Opponent</th>
              <th onClick={() => handleSort("feature")}>Prop</th>
              <th onClick={() => handleSort("sportsbook")}>Sportsbook</th>
              <th onClick={() => handleSort("threshold")}>Threshold</th>
              <th onClick={() => handleSort("odds")}>Odds</th>
              <th onClick={() => handleSort("feature_prediction")}>Prediction</th>
              <th onClick={() => handleSort("true_value")}>Result</th>
              <th onClick={() => handleSort("feature_probability")}>
                Probability
              </th>
              <th>Hit</th>
              <th>Won</th>
              <th>Profit</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((row, index) => {
              const wonAmount = row.hit ? filters.betAmount * row.odds : 0;
              const profit = row.hit
                ? filters.betAmount * (row.odds - 1)
                : -filters.betAmount;

              return (
                <tr key={index}>
                  <td
                    className="clickable"
                    onClick={() => handlePlayerClick(row.id)}
                  >
                    {row.player_name}
                  </td>
                  <td>{row.team_abbreviation}</td>
                  <td>{row.next_opponent}</td>
                  <td>{row.feature}</td>
                  <td>{row.sportsbook}</td>
                  <td>{row.threshold}</td>
                  <td>{row.odds}</td>
                  <td>{Math.round(row.feature_prediction)}</td>
                  <td>{row.true_value}</td>
                  <td>{(row.feature_probability * 100).toFixed(1)}%</td>
                  <td>
                    {row.hit ? (
                      <span className="hit-check">&#10003;</span>
                    ) : (
                      <span className="hit-miss">&#10007;</span>
                    )}
                  </td>
                  <td>${Math.round(wonAmount)}</td>
                  <td>${Math.round(profit)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <p>No data available to display.</p>
      )}
    </div>
  );
};

export default PredictionResultsTable;
