import React, { createContext, useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { combinedInfo } from '../components/CombinedInfo';

const PlayerColorContext = createContext();

export const usePlayerColors = () => useContext(PlayerColorContext);

export const PlayerColorProvider = ({ children }) => {
  // Existing states
  const [teamColors, setTeamColors] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(localStorage.getItem('selectedPlayer') || null);
  const [selectedTeam, setSelectedTeam] = useState(localStorage.getItem('selectedTeam') || null);
  const [opponentTeamColors, setOpponentTeamColors] = useState([]);
  const [opponentLogoSrc, setOpponentLogoSrc] = useState(null);
  const [teamLogoSrc, setTeamLogoSrc] = useState(null);
  const [teamInjuryReport, setTeamInjuryReport] = useState([]);
  const [opponentInjuryReport, setOpponentInjuryReport] = useState([]);
  const [venue, setVenue] = useState('');
  const [location, setLocation] = useState('');
  const [opponentName, setOpponentName] = useState(null);
  const [homeOrAway, setHomeOrAway] = useState(1);
  const [playerHistory, setPlayerHistory] = useState(JSON.parse(localStorage.getItem('playerHistory')) || []);
  const [teamHistory, setTeamHistory] = useState(JSON.parse(localStorage.getItem('teamHistory')) || []);
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')) || []);
  const [colorBlind, setColorBlind] = useState(JSON.parse(localStorage.getItem('colorBlind')) || false);
  const [thresholds, setThresholds] = useState({});
  const [adjustedThresholds, setAdjustedThresholds] = useState({});

  // New state to store next game details
  const [nextGameDetails, setNextGameDetails] = useState({
    date: 'Date Placeholder',
    time: 'Time Placeholder'
  });

  const colorBlindPalette = ['#471c86', '#5e4bel', '#9b7fff', '#250179'];

  const fetchPlayerData = useCallback(
    (playerId) => {
      const player = combinedInfo[playerId];
      if (player) {
        if (colorBlind) {
          setTeamColors(colorBlindPalette);
        } else {
          setTeamColors(player.teamColors);
        }
        setOpponentTeamColors(player.opponentTeamColors);
        setTeamLogoSrc(player.teamLogoSrc);
        setOpponentLogoSrc(player.opponentLogoSrc);
        setTeamInjuryReport(player.teamInjuryReport);
        setOpponentInjuryReport(player.opponentInjuryReport);
        setHomeOrAway(player.home_or_away);
        setOpponentName(player.opponentName);
        setSelectedTeam(player.teamName);
        localStorage.setItem('selectedTeam', player.teamName);

        if (player.home_or_away === 1) {
          setVenue(player.venue);
          setLocation(player.location);
        } else {
          setVenue(player.opponentVenue);
          setLocation(player.opponentLocation);
        }
      }
    },
    [colorBlind]
  );

  useEffect(() => {
    if (selectedPlayer) {
      localStorage.setItem('selectedPlayer', selectedPlayer);
      fetchPlayerData(selectedPlayer);
      setPlayerHistory((prevHistory) => [...prevHistory, selectedPlayer]);
    } else {
      localStorage.removeItem('selectedPlayer');
    }
  }, [selectedPlayer, fetchPlayerData]);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  useEffect(() => {
    localStorage.setItem('colorBlind', JSON.stringify(colorBlind));
    if (selectedPlayer) {
      fetchPlayerData(selectedPlayer);
    }
  }, [colorBlind, selectedPlayer, fetchPlayerData]);

  const selectRandomPlayerFromTeam = useCallback(
    (teamName) => {
      const playersInTeam = Object.keys(combinedInfo).filter(
        (playerId) => combinedInfo[playerId].teamName === teamName
      );
      if (playersInTeam.length > 0) {
        const randomPlayerId = playersInTeam[Math.floor(Math.random() * playersInTeam.length)];
        setSelectedPlayer(randomPlayerId);
        setPlayerHistory((prevHistory) => [...prevHistory, randomPlayerId]);
      }
    },
    []
  );

  const updateTeamSelection = useCallback(
    (teamName) => {
      setTeamHistory((prevHistory) => [...prevHistory, teamName]);
      selectRandomPlayerFromTeam(teamName);
    },
    [selectRandomPlayerFromTeam]
  );

  const removeFromCart = useCallback(
    (playerId) => {
      setCart((cart) => cart.filter((item) => item.playerId !== playerId));
    },
    []
  );

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      teamColors,
      setTeamColors,
      selectedPlayer,
      setSelectedPlayer,
      selectedTeam,
      setSelectedTeam,
      opponentTeamColors,
      setOpponentTeamColors,
      opponentLogoSrc,
      setOpponentLogoSrc,
      teamLogoSrc,
      setTeamLogoSrc,
      teamInjuryReport,
      opponentInjuryReport,
      venue,
      setVenue,
      location,
      setLocation,
      homeOrAway,
      setHomeOrAway,
      opponentName,
      setOpponentName,
      selectRandomPlayerFromTeam,
      updateTeamSelection,
      playerHistory,
      teamHistory,
      cart,
      setCart,
      removeFromCart,
      colorBlind,
      setColorBlind,
      thresholds,
      setThresholds,
      adjustedThresholds,
      setAdjustedThresholds,
      nextGameDetails, // Add next game details to context
      setNextGameDetails, // Add setter for next game details
    }),
    [
      teamColors,
      setTeamColors,
      selectedPlayer,
      setSelectedPlayer,
      selectedTeam,
      setSelectedTeam,
      opponentTeamColors,
      setOpponentTeamColors,
      opponentLogoSrc,
      setOpponentLogoSrc,
      teamLogoSrc,
      setTeamLogoSrc,
      teamInjuryReport,
      opponentInjuryReport,
      venue,
      setVenue,
      location,
      setLocation,
      homeOrAway,
      setHomeOrAway,
      opponentName,
      setOpponentName,
      selectRandomPlayerFromTeam,
      updateTeamSelection,
      playerHistory,
      teamHistory,
      cart,
      setCart,
      removeFromCart,
      colorBlind,
      setColorBlind,
      thresholds,
      setThresholds,
      adjustedThresholds,
      setAdjustedThresholds,
      nextGameDetails,
      setNextGameDetails,
    ]
  );

  return <PlayerColorContext.Provider value={contextValue}>{children}</PlayerColorContext.Provider>;
};
